import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { initAuthUtils } from '../utils/auth';
import { initAxiosUtils } from '../api/axios';
import UserContext from '../context/UserContext';
import { initFileUtils } from '../utils/file';


const UserProvider = ({ children }) => {
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  initAxiosUtils(navigate);
  initAuthUtils(navigate);
  initFileUtils(navigate);

  useEffect(() => {
    const handleSessionStorageEvent = () => {
      const userString = localStorage.getItem('user');
      setUser(userString ? JSON.parse(userString) : null);
    };
    handleSessionStorageEvent();
    window.addEventListener('storage', handleSessionStorageEvent);
    setIsUserInitialized(true);
    return () => {
      window.removeEventListener('storage', handleSessionStorageEvent);
    };
  }, []);

  if (!isUserInitialized) {
    return null;
  }

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

UserProvider.defaultProps = {
  children: null,
};

export default UserProvider;
