import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import Modal from '../Modal';
import Button from '../Button';
import Delete from '../../icons/delete_big.svg';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 24,
  },
  text: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '46px',
    color: '#FFFFFF',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: 32,
  },
  modal: {
    maxWidth: '452px !important',
  },
  buttonContainer: {
    width: '50%',
  },
});

const DeleteUploadModal = ({ onClose, onConfirm }) => {
  const styles = useStyles();
  return (
    <Modal
      key="deleteUpload"
      className={styles.modal}
      onClose={() => onClose()}
      footer={(
        <div className={styles.footer}>
          <Button className={styles.buttonContainer} size="lg" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button className={styles.buttonContainer} type="reversePrimaryLight" size="lg" onClick={() => onConfirm()}>
            Yes, delete it
          </Button>
        </div>
      )}
    >
      <div className={styles.container}>
        <Delete/>
        <div className={styles.text}>
          Are you sure you want to delete this file?
        </div>
      </div>
    </Modal>
  );
};

DeleteUploadModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteUploadModal;
