import fileDownload from 'js-file-download';
import {
  createUpload,
  getSignedMessage,
  getUploadKeys,
  requestAddSign,
  requestAddTransactionHashToSign,
  requestAddTransactionHashToUpload, requestCheckTransaction,
  requestDeleteSign,
  requestDisableUploadSign,
  requestEnableUploadSign,
  setUploadIpfsHash,
} from '../api/file';
import { connectArchiveContract, connectContract, connectWeb3 } from './web3-connector';

// let navigate;
// export const initFileUtils = (navigateFunc) => {
export const initFileUtils = () => {
  // navigate = navigateFunc;
};

let ipfsNode;

const getIpfsNode = () => {
  if (!ipfsNode) {
    // eslint-disable-next-line import/no-unresolved
    return import('ipfs-http-client').then((ipfsHttpClient) => {
      ipfsNode = ipfsHttpClient.create({ url: window.IPFS_API_URL });
      return ipfsNode;
    });
  }
  return Promise.resolve(ipfsNode);
};


// const closeIpfsNode = () => {
//   let promise;
//   if (ipfsNode) {
//     promise = ipfsNode.stop();
//     ipfsNode = null;
//   }
//   return (promise || Promise.resolve());
// };

const keyToCryptoKey = (keyData) => crypto.subtle.importKey(
  'raw',
  Buffer.from(keyData),
  { name: 'AES-CBC' },
  true,
  ['encrypt', 'decrypt'],
);

const encryptFile = (file, iv, keyData) => keyToCryptoKey(keyData).then(
  (key) => window.crypto.subtle.encrypt({ name: 'AES-CBC', iv: Buffer.from(iv) }, key, file),
);

const decryptFile = (file, iv, keyData) => keyToCryptoKey(keyData).then(
  (key) => window.crypto.subtle.decrypt({ name: 'AES-CBC', iv: Buffer.from(iv) }, key, file),
);

const uploadToIpfs = (path, content, progressCallback) => getIpfsNode().then(
  // (node) => node.add({ path, content }, { timeout: 120 * 1000, ttl: '2d', lifetime: '2d' }).then(
  (node) => node.add(
    { path, content },
    {
      timeout: 120 * 1000,
      progress: (uploadedByteLength) => (
        progressCallback ?
          progressCallback(Math.round((uploadedByteLength * 100) / content.byteLength)) :
          null
      ),
    },
  ).then(
    async ({ cid }) => {
      console.log('Uploading file to ipfs.');
      // await node.name.publish(cid, { ttl: '2d', lifetime: '2d' });
      // await node.name.publish(cid, {});
      // await closeIpfsNode();
      return cid.toString();
    },
  ),
);

const downloadFromIpfs = (ipfsHash) => getIpfsNode().then(
  async (node) => {
    const chunks = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const chunk of node.cat(ipfsHash, { timeout: 120 * 1000 })) {
      chunks.push(chunk);
    }
    // await closeIpfsNode();
    return Buffer.concat(chunks);
  },
);

export const uploadFile = (file, progressCallback) => createUpload(file).then(
  (upload) => {
    // navigate('/uploads/processing');
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function () {
        return encryptFile(this.result, upload.iv.data, upload.key.data).then(
          (encryptedFile) => uploadToIpfs(file.name, encryptedFile, progressCallback).then(
            (ipfsHash) => setUploadIpfsHash(upload.id, ipfsHash).then(() => resolve({ ...upload, ipfsHash })),
          ),
        ).catch(reject);
      };
      reader.readAsArrayBuffer(file);
    });
  },
).catch((err) => {
  // closeIpfsNode();
  throw err;
});

export const downloadFile = (id, ipfsHash) => getUploadKeys(id).then(
  (upload) => downloadFromIpfs(ipfsHash).then(
    (encryptedFile) => decryptFile(encryptedFile, upload.iv, upload.key).then(
      (decryptedFile) => {
        fileDownload(decryptedFile, upload.fileName);
      },
    ),
  ),
).catch((err) => {
  // closeIpfsNode();
  console.error(err);
  throw err;
});

export const estimateGas = async (walletId, funcName, id, ipfsHash) => {
  const web3 = await connectWeb3();
  const contract = await connectContract(web3);
  let contractMethod;
  if (funcName === 'SIGN') {
    const { signedMessageSalt, messageSignature } = await getSignedMessage(funcName, id, ipfsHash, 'n', 'c');
    contractMethod = contract.methods[funcName](id, ipfsHash, 'n', 'c', signedMessageSalt, messageSignature);
  } else {
    const { signedMessageSalt, messageSignature } = await getSignedMessage(funcName, id, ipfsHash);
    contractMethod = contract.methods[funcName](id, ipfsHash, '', signedMessageSalt, messageSignature);
  }
  const [gas, gasPrice] = await Promise.all([
    contractMethod.estimateGas({
      from: walletId,
      to: window.BLOCKCHAIN_CHAINBACK_CONTRACT,
    }),
    web3.eth.getGasPrice(),
  ]);
  return {
    fee: ((gas * parseInt(gasPrice, 10)) / (10 ** 18)).toFixed(8),
    gas,
    gasPrice,
  };
};

const CHECK_TRANSACTION_TIMEOUT = 60000;
export const checkTransactionHash = async (transactionHash) => {
  let isTimeoutFinished = false;
  let result = false;
  setTimeout(() => {
    isTimeoutFinished = true;
  }, CHECK_TRANSACTION_TIMEOUT);
  while (!isTimeoutFinished) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await requestCheckTransaction(transactionHash);
      result = true;
      isTimeoutFinished = true;
    } catch (err) {
      // eslint-disable-next-line no-promise-executor-return,no-await-in-loop
      await (new Promise((resolve) => setTimeout(() => resolve(), 3000)));
    }
  }
  return result;
};


// eslint-disable-next-line no-async-promise-executor
const approveArchiveFee = async (walletId, fee) => {
  const web3 = await connectWeb3();
  const feeString = fee.toString();
  const feeNumber = parseFloat(feeString);
  if (feeNumber) {
    const archiveContract = await connectArchiveContract(web3);
    const allowance = await archiveContract.methods.allowance(window.BLOCKCHAIN_CHAINBACK_CONTRACT, walletId).call();
    if (parseFloat(allowance) < feeNumber) {
      await archiveContract.methods.approve(
        window.BLOCKCHAIN_CHAINBACK_CONTRACT,
        web3.utils.toWei(feeString),
      ).send({ from: walletId });
    }
  }
  return web3;
};


// eslint-disable-next-line no-unused-vars
export const enableUploadSign = (
  id,
  ipfsHash,
  options,
  walletId,
  // gas,
  // gasPrice,
) => requestEnableUploadSign(id, options).then(
  ({ signedMessageSalt, messageSignature, feeArchive }) => approveArchiveFee(walletId, feeArchive).then(
    // eslint-disable-next-line no-unused-vars
    (web3) => (new Promise(
      // eslint-disable-next-line no-promise-executor-return,no-async-promise-executor
      async (resolve, reject) => {
        const contract = await connectContract(web3);
        const contractMethod = contract.methods.enableChainbackSign(
          id,
          ipfsHash,
          options.description || '',
          signedMessageSalt,
          messageSignature,
        );
        contractMethod.send({
          from: walletId,
          to: window.BLOCKCHAIN_CHAINBACK_CONTRACT,
          // gas,
          // gasPrice,
        })
          .on(
            'receipt',
            ({ transactionHash }) => requestAddTransactionHashToUpload(id, transactionHash).then(
              () => resolve(transactionHash),
            ),
          )
          .on('error', (err) => {
            console.error(err);
            return requestDisableUploadSign(id).then(reject).catch(reject);
          });
      },
    )),
  ).catch((err) => {
    console.error('Failed to send transaction to blockchain', err);
    return requestDisableUploadSign(id).then(() => {
      throw err;
    }).catch(() => {
      throw err;
    });
  }),
);

export const addSign = async (id, ipfsHash, name, comment, walletId/* , gas, gasPrice */) => {
  try {
    const sign = await requestAddSign(id, name, comment);
    try {
      const web3 = await approveArchiveFee(walletId, sign.feeArchive);
      const contract = await connectContract(web3);
      return new Promise(
        // eslint-disable-next-line no-promise-executor-return
        (resolve, reject) => contract.methods.sign(
          id,
          ipfsHash,
          name || '',
          comment || '',
          sign.signedMessageSalt,
          sign.messageSignature,
        ).send({
          from: walletId,
          to: window.BLOCKCHAIN_CHAINBACK_CONTRACT,
          // gas,
          // gasPrice,
        })
          .on(
            'receipt',
            ({ transactionHash }) => {
              requestAddTransactionHashToSign(id, sign.id, transactionHash).then(
                () => resolve(transactionHash),
              );
            },
          )
          .on('error', (err) => {
            console.error(err);
            return requestDeleteSign(id, sign.id).then(reject).catch((error) => {
              console.log('Send transaction error', error);
              reject(error);
            });
          }),
      );
    } catch (err) {
      console.error('Failed to send transaction to blockchain', err);
      return requestDeleteSign(id, sign.id).then(() => {
        throw err;
      }).catch(() => {
        throw err;
      });
    }
  } catch (err) {
    console.log('Failed to create signature', err);
    throw err;
  }
};

export default { uploadFile, downloadFile, enableUploadSign, addSign, estimateGas, checkTransactionHash };
