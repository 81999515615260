import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Info } from '@icon-park/react';
import Copy from '../../icons/copy.svg';
import PageWrapper from '../PageWrapper';
import Button from '../Button';
import GradientText from '../GradientText';
import { getUpload } from '../../api/file';
import { downloadFile } from '../../utils/file';
import Spinner from '../Spinner';
import FullScreenSpinner from '../FullScreenSpinner';
import UserContext from '../../context/UserContext';
import SignEnabledFileDetailsPage from '../SignEnabledFileDetailsPage';
import ForbiddenModal from '../ForbiddenModal';
import File from '../../icons/file.svg';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 50,
  },
  spinnerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  overview: {
    display: 'flex',
    flexDirection: 'column',
    background: '#020627',
    borderRadius: 30,
    padding: 40,
  },
  blockHeader: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    padding: '19px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  blockRow: {
    columnGap: 40,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '19px 0',
  },
  blockLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 1,
  },
  blockValue: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 4,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: 12,
    padding: 15,
    columnGap: 30,
    marginTop: 50,
    background: 'linear-gradient(60deg, #A18CD1 0%, #FBC2EB 100%)',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '23px',
    color: '#020627',

    '& div': {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
    },
  },
  permissionsBlock: {
    display: 'flex',
    columnGap: 120,
    alignItems: 'center',
    width: 1134,
    padding: 35,
    background: 'linear-gradient(60deg, #F094FA 0%, #F5576E 100%)',
    marginTop: 40,
    borderRadius: 30,

    '@media (max-width: 1250px)': {
      width: 'fit-content',
    },
  },
  permissionsBlockTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    flex: 2,
  },
  permissionsBlockButtonContainer: {
    display: 'flex',
    flex: 1,
  },
  signButton: {
    fontWeight: 800,
  },
  permissionsBlockTextPrimary: {
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '52px',
    color: '#020627',
    maxWidth: 625,
    fontFamily: 'Jost',
    fontStyle: 'normal',
  },
  permissionsBlockTextSecondary: {
    maxWidth: 625,
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '32px',
    color: '#020627',
  },
  copyRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
  },
  linkRow: {
    cursor: 'pointer',
    fontWeight: 700,

    '&:hover': {
      color: '#8B55FF',

      '& $copySign': {
        color: '#8B55FF',
      },
    },
  },
  fileLinkRow: {
    padding: 16,
    border: '1px solid rgba(221, 214, 237, 0.5)',
    borderRadius: 12,
    columnGap: 15,
    background: 'rgba(2, 6, 39, 0.5)',

    '& div': {
      '& span': {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: '24px',
        letterSpacing: '0.005em',
      },
    },
  },
  copySign: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(221, 214, 237, 0.5)',

    '&:hover': {
      '& g': {
        opacity: 1,

        '& path': {
          stroke: '#8B55FF',
        },
      },
    },
  },
});

const renderCopyRow = (label, value, styles, isLink, isFileLink) => (
  value ?
    (
      <div className={classNames(styles.copyRow, isFileLink ? styles.fileLinkRow : null)}>
        <div
          className={isLink ? styles.linkRow : null}
          onClick={() => (isLink ? window.open(value, '_blank') : null)}
        >
          {
            isFileLink ?
              <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">{(label || value)}</GradientText> :
              (label || value)
          }
        </div>
        <div>
          <Copy
            data-tooltip-id="copy-tooltip"
            className={styles.copySign}
            onClick={() => navigator.clipboard.writeText(label || value)}
          />
        </div>
      </div>
    ) :
    null
);

const FileDetailsPage = () => {
  const styles = useStyles();
  const { id } = useParams();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [upload, setUpload] = useState();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [showForbiddenModal, setShowForbiddenModal] = useState(false);
  const [searchParams] = useSearchParams();
  const isFromExplorer = searchParams.get('isFromExplorer');
  const isFromMain = searchParams.get('isFromMain');

  useEffect(() => {
    setUpload(null);
    if (id !== 'processing') {
      getUpload(id).then((file) => {
        setUpload({ ...file, createdAt: new Date(file.createdAt) });
        if (window.location.pathname.includes('/uploads/') && window.location.pathname.includes('/download')) {
          setIsFileLoading(true);
          downloadFile(file.id, file.ipfsHash).then(
            () => setIsFileLoading(false),
          ).catch(
            () => {
              setIsFileLoading(false);
              setShowForbiddenModal(true);
            },
          );
        }
      });
    }
  }, [id]);

  return (
    <PageWrapper
      header="File Details"
      linksChain={[
        { value: '/', label: 'Chainback' },
        (
          (
            (isFromExplorer && upload?.isSignEnabled && upload?.isTransactionCommitted) ||
            isFromMain ||
            !user ||
            !upload ||
            // eslint-disable-next-line react/destructuring-assignment
            (upload.userId !== user.id)
          ) ?
            (
              (isFromExplorer && upload?.isSignEnabled && upload?.isTransactionCommitted) ?
                { value: '/explorer', label: 'Chainback Explorer' } :
                null
            ) :
            { value: '/uploads', label: 'My Uploads' }
        ),
        (id === 'processing') ?
          null :
          {
            // eslint-disable-next-line max-len
            value: `/uploads/${id}${isFromExplorer && upload?.isSignEnabled && upload?.isTransactionCommitted ? '?isFromExplorer=true' : ''}${isFromMain ? '?isFromMain=true' : ''}`,
            label: `${id.substring(0, 10)}****${id.substring(id.length - 10)}`,
          },
      ]}
    >
      <div className={styles.container}>
        {showForbiddenModal ? <ForbiddenModal onClose={() => setShowForbiddenModal(false)}/> : null}
        {isFileLoading ? <FullScreenSpinner/> : null}
        {
          upload ?
            (upload.isSignEnabled && upload.isTransactionCommitted) ?
              (<SignEnabledFileDetailsPage upload={upload}/>) :
              (
                <div className={styles.overview}>
                  <div className={styles.blockHeader}>File Overview</div>
                  <div className={styles.blockRow}>
                    <div className={styles.blockLabel}>
                      File name
                    </div>
                    <div className={styles.blockValue}>
                      <File/>
                      &nbsp;
                      {upload.fileName}
                    </div>
                  </div>
                  <div className={styles.blockRow}>
                    <div className={styles.blockLabel}>
                      IPFS Hash
                    </div>
                    <div className={styles.blockValue}>
                      {renderCopyRow(null, upload.ipfsHash, styles)}
                    </div>
                  </div>
                  <div className={styles.blockRow}>
                    <div className={styles.blockLabel}>
                      Chainback Hash
                    </div>
                    <div className={styles.blockValue}>
                      {renderCopyRow(null, upload.id, styles)}
                    </div>
                  </div>
                  <div className={styles.blockRow}>
                    <div className={styles.blockLabel}>
                      Timestamp
                    </div>
                    <div className={styles.blockValue}>
                      {(new Date((new Date()).getTime() - upload.createdAt.getTime())).formatRemainingTime()}
                    </div>
                  </div>
                  <div className={styles.blockRow}>
                    <div className={styles.blockLabel}>
                      File download
                    </div>
                    <div className={styles.blockValue}>
                      <Button
                        size="sm"
                        type="primary"
                        onClick={() => {
                          setIsFileLoading(true);
                          downloadFile(upload.id, upload.ipfsHash).then(
                            () => setIsFileLoading(false),
                          ).catch(
                            () => {
                              setIsFileLoading(false);
                              setShowForbiddenModal(true);
                            },
                          );
                        }}
                      >
                        Download
                      </Button>
                    </div>
                  </div>
                  <div className={styles.blockRow}>
                    <div className={styles.blockLabel}>
                      File link
                    </div>
                    <div className={styles.blockValue}>
                      {renderCopyRow(null, `${window.location.origin}/uploads/${upload.id}`, styles, false, true)}
                    </div>
                  </div>

                  <div className={styles.alert}>
                    <Info size={32} fill="#020627"/>
                    <div>
                      Your file is now encrypted and stored on IPFS, share the link to grant people access.
                    </div>
                  </div>
                  <div className={styles.permissionsBlock}>
                    <div className={styles.permissionsBlockTextContainer}>
                      <div className={styles.permissionsBlockTextPrimary}>
                        Manage Permissions and Allow Signing with Ethereum
                      </div>
                      <div className={styles.permissionsBlockTextSecondary}>
                        Every successfully archived peace of our Chainback archive available via personal link and
                        via
                        Chainback explorer.
                      </div>
                    </div>
                    <div className={styles.permissionsBlockButtonContainer}>
                      <Button
                        type="dark"
                        className={styles.signButton}
                        onClick={() => {
                          let enableLink = `/uploads/${id}/enable`;
                          if (
                            (isFromExplorer && upload?.isSignEnabled && upload?.isTransactionCommitted) ||
                            isFromMain
                          ) {
                            enableLink += '?';
                            if (isFromExplorer && upload?.isSignEnabled && upload?.isTransactionCommitted) {
                              enableLink += 'isFromExplorer=true&';
                            }
                            if (isFromMain) {
                              enableLink += 'isFromMain=true';
                            }
                          }
                          navigate(enableLink);
                        }}
                      >
                        Set Up File Access
                      </Button>
                    </div>
                  </div>
                </div>
              ) :
            (<div className={styles.spinnerContainer}><Spinner/></div>)
        }
      </div>
    </PageWrapper>
  );
};


export default FileDetailsPage;
