import axios from './axios';

export const requestLoginStart = (walletId) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/login/start`,
  { walletId },
).then(({ data }) => data);

export const requestLoginComplete = (walletId, signature) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/login/complete`,
  { walletId, signature },
).then(({ data }) => data);

export const requestLogout = () => axios.post(`${window.CHAINBACK_SERVER_URL}/api/logout`);

export const checkAuth = () => axios.post(`${window.CHAINBACK_SERVER_URL}/api/auth/check`);

export default { requestLoginStart, requestLoginComplete, requestLogout, checkAuth };
