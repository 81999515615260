import {
  requestLoginComplete,
  requestLoginStart,
  requestLogout,
} from '../api/auth';
import { disconnectWeb3 } from '../api/axios';


// let navigate;

// export const initAuthUtils = (navigateFunc) => {
export const initAuthUtils = () => {
  // navigate = navigateFunc;
};

const loginCallback = (session) => {
  localStorage.setItem('session', JSON.stringify(session));
  localStorage.setItem('user', JSON.stringify({ id: session.userId, walletId: session.walletId }));
  localStorage.setItem('sessionToken', session.token);
  localStorage.setItem('sessionTokenExpirationDate', JSON.stringify(session.expirationDate));
  window.dispatchEvent(new Event('storage'));
};

export const startLogin = (walletId) => requestLoginStart(walletId);

export const completeLogin = (walletId, signature) => requestLoginComplete(walletId, signature).then(loginCallback);

export const processLogout = () => {
  localStorage.removeItem('session');
  localStorage.removeItem('user');
  localStorage.removeItem('sessionToken');
  localStorage.removeItem('sessionTokenExpirationDate');
  window.dispatchEvent(new Event('storage'));
  disconnectWeb3();
};


export const logout = () => requestLogout().then(processLogout);

export default { initAuthUtils, startLogin, completeLogin, logout };
