import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Table from '../Table';
import GradientLabel from '../GradientLabel';
import { getTransactions } from '../../api/file';
import PublicFile from '../../icons/public_file.svg';
import PrivateFile from '../../icons/private_file.svg';


const useStyles = createUseStyles({
  container: {
    padding: 30,

    '@media (max-width: 1440px)': {
      padding: '30px 0',
    },
  },
  hash: {
    cursor: 'pointer',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',

    '&:hover': {
      color: '#8B55FF',
    },
  },
  hashContainer: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
  },
  description: {
    minWidth: 255,
    maxWidth: 355,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  from: {
    maxWidth: 200,
  },
});

const getColumns = (styles, navigate) => [
  {
    label: 'tx Hash',
    width: 160,
    getValue: (item) => {
      const id = item.signatureId || item.uploadId;
      return (
        <div
          className={styles.hash}
          onClick={
            () => navigate(
              `/uploads/${item.uploadId}${(item.type === 'SIGN') ? `/signatures/${item.id}` : ''}?isFromMain=true`,
            )
          }
        >
          {`${id.substring(0, 15)}...`}
        </div>
      );
    },
  },
  {
    label: 'Type',
    align: 'center',
    width: 165,
    getValue: (item) => (
      <GradientLabel backgroundColor={(item.type === 'FILE') ? '#13173fa3' : '#271a53'}>
        {item.type.capitalize()}
      </GradientLabel>
    ),
  },
  {
    label: 'File Hash',
    getValue: (item) => (
      <div className={styles.hashContainer}>
        {(item.isPublic === false) ? <PrivateFile/> : <PublicFile/>}
        <div
          className={styles.hash}
          onClick={() => navigate(`/uploads/${item.uploadId}?isFromExplorer=true`)}
        >
          {`${item.uploadId.substring(0, 15)}...`}
        </div>
      </div>
    ),
  },
  {
    label: 'From',
    className: styles.from,
    getValue: (item) => (
      <div
        className={styles.hash}
        onClick={() => window.open(`${window.ETHERSCAN_URL}/address/${item.from}`, '_blank')}
      >
        {`${item.from.substring(0, 8)}...${item.from.substring(item.from.length - 8)}`}
      </div>
    ),
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    className: styles.description,
    minWidth: 255,
    maxWidth: 355,
    width: 355,
    getValue: (item) => (item.signerComment || item.description),
  },
  {
    label: 'Signatures',
    align: 'center',
    getValue: (item) => (item.signatureId ? '' : item.signaturesAmount),
    defaultValue: ' ',
  },
];


const Transactions = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <Table columns={getColumns(styles, navigate)} loadItems={getTransactions} maxItems={5} hidePagination={true}/>
    </div>
  );
};


export default Transactions;
