import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';


const useStyles = createUseStyles({
  container: {
    width: 'fit-content',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: ({ type }) => ((type === 'link') ? 'column' : 'row'),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: ({ isRounded }) => (isRounded ? 50 : 6),
    fontStyle: 'normal',
    fontWeight: 800,
    color: '#FFFFFF',
  },
  lg: {
    height: 65,
    minWidth: 155,
    fontSize: 18,

    '& $border': {
      padding: '0 44px',
    },
  },
  md: {
    height: 54,
    minWidth: 155,
    fontSize: 16,

    '& $border': {
      padding: '0 25px',
    },
  },
  sm: {
    height: 36,
    minWidth: 87,
    fontSize: 15,

    '& $border': {
      padding: '0 24px',
    },
  },
  primary: {
    background: 'linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%)',

    '&:hover': {
      background: 'linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%)',
    },
  },
  reversePrimary: {
    background: 'linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%)',

    '&:hover': {
      background: 'linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%)',
    },
  },
  reversePrimaryLight: {
    background: 'linear-gradient(60deg, #F094FA 13.4%, #F5576E 86.6%)',

    '&:hover': {
      background: 'linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%)',
    },
  },
  outline: {
    '& $border': {
      border: '1px solid transparent',
      // eslint-disable-next-line max-len
      background: ({ backgroundColor }) => `linear-gradient(${backgroundColor || '#13173F'} 0 0) padding-box, linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%) border-box`,
    },

    '&:hover': {
      background: 'linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%)',

      '& $border': {
        background: 'none',
      },
    },
  },
  reverseOutline: {
    '& $border': {
      border: '1px solid transparent',
      // eslint-disable-next-line max-len
      background: ({ backgroundColor }) => `linear-gradient(${backgroundColor || '#13173F'} 0 0) padding-box, linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%) border-box`,
    },

    '&:hover': {
      background: 'linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%)',

      '& $border': {
        background: 'none',
      },
    },
  },
  reverseDarkOutline: {
    '& $border': {
      border: '1px solid transparent',
      // eslint-disable-next-line max-len
      background: ({ backgroundColor }) => `linear-gradient(${backgroundColor || '#13173F'} 0 0) padding-box, linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%) border-box`,
    },

    '&:hover': {
      background: 'transparent',

      '& $border': {
        border: '1px solid transparent',
        // eslint-disable-next-line max-len
        background: 'linear-gradient(#020627 0 0) padding-box, linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%) border-box',
      },
    },
  },
  dark: {
    background: '#020627',

    '&:hover': {
      background: 'transparent',

      '& $border': {
        height: 52,
        padding: '0 24px',
        border: '1px solid transparent',
        // eslint-disable-next-line max-len
        background: 'linear-gradient(#13173F 0 0) padding-box, linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%) border-box',
      },
    },
  },
  link: {
    fontWeight: 800,
    borderRadius: 0,
    minWidth: 0,
    fontSize: 22,

    '&:hover': {
      '& $linkBorder': {
        background: 'linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%)',
      },
    },
  },
  linkBorder: {
    height: 2,
    width: '100%',
    background: 'linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%)',
  },
  border: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    border: 'none',
    background: 'none',
    padding: 1,
    borderRadius: ({ isRounded }) => (isRounded ? 50 : 6),
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
});


const Button = ({ children, type, size, isRounded, onClick, backgroundColor, className, disabled }) => {
  const styles = useStyles({ isRounded, type, backgroundColor });

  return (
    <div
      className={classNames(styles.container, styles[type], styles[size], className, disabled ? styles.disabled : null)}
      onClick={(e) => (disabled ? null : onClick(e))}
    >
      {
        (type === 'link') ?
          (
            <>
              {children}
              <div className={styles.linkBorder}/>
            </>
          ) :
          (
            <div className={styles.border}>
              {children}
            </div>
          )
      }
    </div>
  );
};


Button.propTypes = {
  disabled: PropTypes.bool,
  isRounded: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'reversePrimary',
    'reversePrimaryLight',
    'dark',
    'outline',
    'reverseOutline',
    'reverseDarkOutline',
    'link',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onClick: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};


Button.defaultProps = {
  className: undefined,
  backgroundColor: undefined,
  isRounded: false,
  type: 'outline',
  size: 'md',
  disabled: false,
};


export default Button;
