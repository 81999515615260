import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';


const useStyles = createUseStyles({
  container: {
    display: 'inline-block',
    '-webkit-background-clip': 'text !important',
    backgroundClip: 'text',
    '-webkit-text-fill-color': 'transparent',
    textFillColor: 'transparent',
    background: ({ colorFrom, colorMiddle, colorTo }) => (
      `linear-gradient(90deg, ${colorFrom} 0%,${
        colorMiddle ? `${colorMiddle} 50%,` : ''
      }${colorTo} 100%)`
    ),
    backgroundColor: 'transparent',
    mixBlendMode: 'normal',
    color: 'transparent',
  },
});


const GradientText = ({ children, className, colorFrom, colorMiddle, colorTo }) => {
  const styles = useStyles({ colorFrom, colorMiddle, colorTo });
  return (
    <span className={classNames(styles.container, className)}>
      {children}
    </span>
  );
};


GradientText.propTypes = {
  className: PropTypes.string,
  colorFrom: PropTypes.string.isRequired,
  colorMiddle: PropTypes.string,
  colorTo: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};


GradientText.defaultProps = {
  className: undefined,
  colorMiddle: undefined,
};


export default GradientText;
