import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { Search } from '@icon-park/react';
import PageWrapper from '../PageWrapper';
import GradientLabel from '../GradientLabel';
import Table from '../Table';
import { getTransactions } from '../../api/file';
import Input from '../Input';
import PublicFile from '../../icons/public_file.svg';
import PrivateFile from '../../icons/private_file.svg';


const useStyles = createUseStyles({
  container: {
    position: 'relative',
    padding: '30px 40px',
    borderRadius: 30,
    background: '#020627',

    '& td': {
      padding: '23.5px 8px',
    },
  },
  hash: {
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: 16,

    '&:hover': {
      color: '#8B55FF',
    },
  },
  input: {
    position: 'absolute',
    width: 344,
    right: 0,
    top: -89,

    '& input': {
      background: 'rgba(2, 6, 39, 0.5)',
      border: '1px solid rgba(118, 80, 220, 0.2)',

      '&:hover': {
        border: '1px solid rgba(118, 80, 220, 0.6)',
        color: '#FFFFFF',
      },

      '&:focus': {
        border: '1px solid rgba(118, 80, 220, 1)',
        color: '#FFFFFF',
      },
    },

    '& svg': {
      opacity: 0.5,
    },
  },
  hashContainer: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
  },
  description: {
    minWidth: 255,
    maxWidth: 355,
    width: 355,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  from: {
    maxWidth: 200,
  },
});

const getColumns = (navigate, styles) => [
  {
    label: 'tx Hash',
    width: 160,
    getValue: (item) => {
      const id = item.signatureId || item.uploadId;
      return (
        <div
          className={styles.hash}
          onClick={
            () => navigate(
              `/uploads/${item.uploadId}${(item.type === 'SIGN') ? `/signatures/${item.id}` : ''}?isFromExplorer=true`,
            )
          }
        >
          {`${id.substring(0, 15)}...`}
        </div>
      );
    },
  },
  {
    label: 'Type',
    align: 'center',
    width: 165,
    getValue: (item) => (
      <GradientLabel backgroundColor={(item.type === 'FILE') ? '#13173fa3' : '#070027'}>
        {item.type.capitalize()}
      </GradientLabel>
    ),
  },
  {
    label: 'File Hash',
    getValue: (item) => (
      <div className={styles.hashContainer}>
        {(item.isPublic === false) ? <PrivateFile/> : <PublicFile/>}
        <div
          className={styles.hash}
          onClick={() => navigate(`/uploads/${item.uploadId}?isFromExplorer=true`)}
        >
          {`${item.uploadId.substring(0, 15)}...`}
        </div>
      </div>
    ),
  },
  {
    label: 'From',
    className: styles.from,
    getValue: (item) => (
      <div
        className={styles.hash}
        onClick={() => window.open(`${window.ETHERSCAN_URL}/address/${item.from}`, '_blank')}
      >
        {`${item.from.substring(0, 8)}...${item.from.substring(item.from.length - 8)}`}
      </div>
    ),
  },
  {
    id: 'description',
    label: 'Description',
    align: 'left',
    className: styles.description,
    minWidth: 255,
    maxWidth: 355,
    getValue: (item) => (item.signerComment || item.description),
  },
  {
    label: 'Signatures',
    align: 'center',
    getValue: (item) => (item.signatureId ? '' : item.signaturesAmount),
    defaultValue: ' ',
  },
];


const ExplorerPage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [filter, setFilter] = useState('');
  const tableRef = useRef();

  return (
    <PageWrapper
      header="Chainback Explorer"
      linksChain={[{ value: '/', label: 'Chainback' }, { value: '/explorer', label: 'Chainback Explorer' }]}
    >
      <div className={styles.container}>
        <Input
          placeHolder="Search..."
          className={styles.input}
          value={filter}
          onChange={(value) => {
            setFilter(value);
            tableRef?.current?.refresh(value);
          }}
          icon={<Search size={24} fill="#DDD6ED"/>}
        />
        <Table
          parentRef={tableRef}
          columns={getColumns(navigate, styles)}
          loadItems={(limit, offset, search) => getTransactions(limit, offset, search)}
        />
      </div>
    </PageWrapper>
  );
};


export default ExplorerPage;
