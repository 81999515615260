import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ({ size }) => (size || 80),
    height: ({ size }) => (size || 80),
  },
  spinner: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: ({ size }) => (size || 80),
    height: ({ size }) => (size || 80),
    borderRadius: '10rem',
    border: '3px solid white',
    background: 'linear-gradient(#F094FA, #46FF7A, #52CBE7)',
    boxShadow: '0px 0px 100px -50px black',
    animation: '$animate 1s linear infinite',
  },
  '@keyframes animate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  text: {
    position: 'absolute',
    color: '#FFFFFF',
    fontSize: 50,
    fontStyle: 'normal',
    fontFamily: 'Jost',
    fontWeight: 700,
    lineHeight: '72px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Spinner = ({ text, size }) => {
  const styles = useStyles({ size });
  return (
    <div className={styles.container}>
      <div className={styles.spinner}/>
      {text ? <div className={styles.text}>{text}</div> : null}
    </div>
  );
};

Spinner.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
};

Spinner.defaultProps = {
  text: null,
  size: 80,
};

export default Spinner;
