import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Close } from '@icon-park/react';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    maxWidth: '90%',
    minWidth: 452,
    maxHeight: '80%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 40,
    border: 0,
    borderRadius: 30,
    background: 'linear-gradient(125deg, #282E5C 0%, #020627 100%)',
  },
  mobileContent: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 0,
  },
  overlay: {
    backgroundColor: '#0000009c',
    zIndex: 999999,
  },
};

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '46px',
    color: '#FFFFFF',
    paddingBottom: 25,
    fontFamily: 'Jost',
    fontStyle: 'normal',
  },
  footer: {
    marginTop: 24,
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    width: 28,
    height: 28,
    borderRadius: 28,
    zIndex: 99999999,
    background: '#863FFE',
    top: 10,
    right: 10,

    '&:hover': {
      background: '#020627',
    },
  },
  close: {
    width: 18,
    height: 18,
  },
});

const Modal = ({ className, header, showClose, headerClassName, footer, onClose, children, hideScroll }) => {
  const styles = useStyles();

  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    document.getElementsByTagName('body')[0].style.position = 'fixed';
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = '';
      document.getElementsByTagName('body')[0].style.position = '';
    };
  }, []);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ReactModal
        isOpen={true}
        onRequestClose={() => (onClose ? onClose() : null)}
        style={{
          ...customStyles,
          content: {
            ...customStyles.content,
            overflow: hideScroll ? 'visible' : 'auto',
          },
        }}
        ariaHideApp={false}
      >
        {
          showClose ?
            (
              <div className={styles.closeContainer} onClick={() => onClose()}>
                <div className={styles.close}>
                  <Close size={18}/>
                </div>
              </div>
            ) :
            null
        }
        <div className={classNames(styles.container, className)}>
          {
            header ?
              (
                <div className={headerClassName || styles.header}>
                  <div>
                    {header}
                  </div>
                </div>
              ) :
              null
          }
          <div className={header ? styles.content : ''}>
            {children}
          </div>
          {
            footer ?
              (
                <div className={styles.footer}>
                  {footer}
                </div>
              ) :
              null
          }
        </div>
      </ReactModal>
    </div>
  );
};

Modal.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
  showClose: PropTypes.bool,
  hideScroll: PropTypes.bool,
  headerClassName: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};

Modal.defaultProps = {
  children: null,
  showClose: false,
  className: null,
  hideScroll: false,
  header: null,
  headerClassName: null,
  footer: null,
  onClose: null,
};


export default Modal;
