import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
// eslint-disable-next-line import/no-unresolved
import { Tooltip } from 'react-tooltip';
import Header from '../Header';
import Footer from '../Footer';
import UserContext from '../../context/UserContext';
import { checkAuth } from '../../api/auth';
import { processLogout } from '../../utils/auth';

// eslint-disable-next-line import/no-unresolved
import 'react-tooltip/dist/react-tooltip.css';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#13173F',
  },
  header: {
    position: 'fixed',
    width: '100%',
    backdropFilter: 'blur(10px)',
    backgroundColor: ({ scrollTop }) => ((scrollTop > 91) ? 'rgba(19, 23, 63, 0.6)' : 'transparent'),
    zIndex: 999,
  },
  body: {
    width: '100%',
    marginTop: 106,
  },
  footer: {
    width: '100%',
    margin: 'auto',
    maxWidth: 1440,
  },
  tooltip: {
    zIndex: 99999999,
    background: '#712AF9',
    opacity: 1,
    color: '#FFFFFF',
  },
});

let loginCheckInterval;

const Layout = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(window.scrollY);
  const styles = useStyles({ scrollTop });
  const user = useContext(UserContext);

  const checkFunc = () => checkAuth().catch((err) => {
    if ((err?.status === 401) && loginCheckInterval) {
      clearInterval(loginCheckInterval);
      loginCheckInterval = null;
      processLogout();
    }
  });

  useEffect(() => {
    const handleScroll = () => setScrollTop(window.scrollY);
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    checkFunc();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!loginCheckInterval && user) {
      loginCheckInterval = setInterval(checkFunc, 10000);
    } else if (loginCheckInterval) {
      clearInterval(loginCheckInterval);
      loginCheckInterval = null;
    }
    return () => {
      if (loginCheckInterval) {
        clearInterval(loginCheckInterval);
        loginCheckInterval = null;
      }
    };
  }, [user]);

  return (
    <div className={styles.container}>
      <Tooltip
        className={styles.tooltip}
        id="copy-tooltip"
        content="Copied!"
        delayHide={1500}
        openOnClick={true}
      />
      <Tooltip
        className={styles.tooltip}
        id="restriction-tooltip"
        content="restr!"
      />
      <div className={styles.header}>
        <Header scrollTop={scrollTop}/>
      </div>
      <div className={styles.body}>
        {children}
      </div>
      <div className={styles.footer}>
        <Footer/>
      </div>
    </div>
  );
};


Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};


export default Layout;
