import axios from './axios';

export const getUploads = (limit, offset, q) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads`,
  { params: { offset: offset || 0, limit: limit || 10, sort: 'createdAt', order: 'DESC', q } },
).then(({ data }) => data);


export const getUpload = (id) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}`,
).then(({ data }) => data);


export const getUploadKeys = (id) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}/keys`,
).then(({ data }) => data);

export const deleteUpload = (id) => axios.delete(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}`,
).then(() => {
});

export const createUpload = (file) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads`,
  { fileName: file.name, mimeType: file.type, size: file.size },
).then(({ data }) => data);

export const setUploadIpfsHash = (id, ipfsHash) => axios.patch(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}/ipfs_hash`,
  { ipfsHash },
).then(({ data }) => data);

export const requestEnableUploadSign = (id, options) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}/sign/enable`,
  options,
).then(({ data }) => data);

export const requestDisableUploadSign = (id) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}/sign/disable`,
).then(({ data }) => data);

export const requestAddSign = (id, name, comment) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}/signatures`,
  { name, comment },
).then(({ data }) => data);

export const requestDeleteSign = (uploadId, signatureId) => axios.delete(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${uploadId}/signatures/${signatureId}`,
).then(({ data }) => data);

export const requestAddTransactionHashToSign = (uploadId, signatureId, transactionHash) => axios.patch(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${uploadId}/signatures/${signatureId}/transaction_hash`,
  { transactionHash },
).then(({ data }) => data);

export const requestAddTransactionHashToUpload = (uploadId, transactionHash) => axios.patch(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${uploadId}/transaction_hash`,
  { transactionHash },
).then(({ data }) => data);

export const getSignatures = (id, limit = 10, offset = 0) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${id}/signatures`,
  { params: { offset: offset || 0, limit: limit || 10, sort: 'createdAt', order: 'DESC' } },
).then(({ data }) => data);

export const getSignature = (uploadId, signatureId) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/uploads/${uploadId}/signatures/${signatureId}`,
).then(({ data }) => data);

export const getTransactions = (limit, offset, q) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/transactions`,
  { params: { offset: offset || 0, limit: limit || 10, sort: 'createdAt', order: 'DESC', q } },
).then(({ data }) => data);

export const requestCheckTransaction = (transactionHash) => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/transactions/${transactionHash}`,
).then(({ data }) => data);

export const getFileFee = () => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/fee/file`,
).then(({ data }) => data);

export const getSignatureFee = () => axios.get(
  `${window.CHAINBACK_SERVER_URL}/api/file/fee/signature`,
).then(({ data }) => data);

export const getSignedMessage = (type, id, ipfsHash, name, comment) => axios.post(
  `${window.CHAINBACK_SERVER_URL}/api/file/message/sign`,
  { type, id, ipfsHash, name, comment },
).then(({ data }) => data);

export default {
  getFileFee,
  getSignatureFee,
  getUploads,
  getUpload,
  getUploadKeys,
  deleteUpload,
  createUpload,
  setUploadIpfsHash,
  requestEnableUploadSign,
  requestAddSign,
  getSignatures,
  getSignature,
  getTransactions,
  requestAddTransactionHashToUpload,
  requestAddTransactionHashToSign,
  requestDisableUploadSign,
  requestDeleteSign,
  requestCheckTransaction,
  getSignedMessage,
};
