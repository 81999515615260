import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { createUseStyles } from 'react-jss';
import Spinner from '../Spinner';

const useStyles = createUseStyles({
  container555: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999999,
    background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
  },
});

const FullScreenSpinner = ({ text, size }) => {
  const styles = useStyles();
  return createPortal(
    <div className={styles.container555}><Spinner text={text} size={size}/></div>,
    document.body,
  );
};

FullScreenSpinner.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
};

FullScreenSpinner.defaultProps = {
  text: null,
  size: 80,
};

export default FullScreenSpinner;
