import React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import LogoVertical from '../../icons/logo-vertical.svg';
import MediumIcon from '../../icons/medium.svg';
import TwitterIcon from '../../icons/twitter.svg';
import TelegramIcon from '../../icons/telegram.svg';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 80,
    marginBottom: 29,
    color: '#7780A1',

    '@media (max-width: 1440px)': {
      padding: '0 34px',
    },
  },
  networks: {
    display: 'flex',
    columnGap: 50,
  },
  footer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    paddingTop: 28,
    marginTop: 40,

    '& > div': {
      flexGrow: 1,
    },
  },
  copyright: {
    textDecoration: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    marginLeft: 15,

    '&:hover': {
      color: '#8842FE',
      textDecoration: 'underline',
    },
  },
  footerLine: {
    top: 0,
    height: 2,
    width: '100%',
    background: 'rgba(119, 128, 161, 0.1)',
    position: 'absolute',
  },
  links: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: 50,
    fontSize: 18,
    marginRight: 15,

    '& > div': {
      fontFamily: 'Jost',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,

      '& > a': {
        color: '#7780A1',
        fontWeight: 600,
        textDecoration: 'none',

        '&:hover': {
          color: '#8842FE',
          textDecoration: 'underline',
        },
      },
    },
  },
  icon: {
    cursor: 'pointer',
    color: '#FFFFFF',
    width: 75,
    height: 75,
    transition: 'all .3s ease-in-out',

    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  logo: {
    cursor: 'pointer',
  },
  manyThings: {
    fontSize: 22,
  },
});


const Footer = () => {
  const styles = useStyles({ pageWidth: window.innerWidth - 15 });
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <LogoVertical className={styles.logo} onClick={() => navigate('/')}/>
      <div className={styles.manyThings}>
        Many things that have no value for us today will be a sacred relic of information for future generations
      </div>
      <div className={styles.networks}>
        <MediumIcon
          className={styles.icon}
          onClick={() => window.open('https://medium.com/@chainback.archive', '_blank')?.focus()}
        />
        <TwitterIcon
          className={styles.icon}
          onClick={() => window.open('https://twitter.com/ChainbackWeb3', '_blank')?.focus()}
        />
        <TelegramIcon
          className={styles.icon}
          onClick={() => window.open('https://t.me/chainback_archive', '_blank')?.focus()}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLine}/>
        © 2023
        <a
          className={styles.copyright}
          onClick={() => window.open('https://chainback.org/', '_blank')}
        >
          Chainback Web3 Archive
        </a>
        <div className={styles.links}>
          <div>
            <a href="mailto:partners@chainback.org">
              partners@chainback.org
            </a>
          </div>
          <div>
            <a
              href="https://drive.google.com/file/d/1wtzmijhSoJJmIO5S2PRDH3-rV3IH93xv/view"
              target="_blank"
              rel="noreferrer"
            >
              Whitepaper
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Footer;
