import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import GradientText from '../GradientText';
import MdiCaret from '../../icons/mdi-caret.svg';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 50,
    maxWidth: 1440,
    margin: '100px auto',

    '@media (max-width: 1440px)': {
      padding: '0  34px',
    },
  },
  header: {
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    color: '#FFFFFF',
    fontFamily: 'Jost',
    fontStyle: 'normal',
  },
  linksChainContainer: {
    display: 'flex',
    columnGap: 4,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    letterSpacing: '0.04em',
    color: '#DDD6ED',
  },
  link: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    letterSpacing: '0.04em',
  },
  linkPointer: {
    cursor: 'pointer',

    '&:hover': {
      color: '#863FFE',
    },
  },
  linkArrow: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    letterSpacing: '0.04em',
  },
});


const PageWrapper = ({ children, header, linksChain }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const links = linksChain?.filter((item) => item);

  return (
    <div className={styles.container}>
      {
        header ?
          (
            <div className={styles.header}>
              {header}
            </div>
          ) :
          null
      }
      {
        links?.length ?
          (
            <div className={styles.linksChainContainer}>
              {
                links.map((link, index) => {
                  let arrow;
                  if (index) {
                    arrow = (
                      <div className={styles.linkArrow}>
                        <MdiCaret/>
                      </div>
                    );
                  }
                  return (
                    <React.Fragment key={link.value}>
                      {arrow}
                      <div
                        className={classNames(styles.link, (index === (links.length - 1)) ? null : styles.linkPointer)}
                        onClick={() => ((index === (links.length - 1)) ? null : navigate(link.value))}
                      >
                        {
                          (index === (links.length - 1)) ?
                            (
                              <GradientText colorFrom="#46FF7A" colorMiddle="#52CBE7" colorTo="#7650DC">
                                {link.label}
                              </GradientText>
                            ) :
                            link.label
                        }
                      </div>
                    </React.Fragment>
                  );
                })
              }
            </div>
          ) :
          null
      }
      <div>
        {children}
      </div>
    </div>
  );
};


PageWrapper.propTypes = {
  linksChain: PropTypes.arrayOf({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};


PageWrapper.defaultProps = {
  header: undefined,
  linksChain: undefined,
};


export default PageWrapper;
