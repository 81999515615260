import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Copy from '../../icons/copy.svg';
import Ethereum from '../../icons/ethereum.svg';
import ERC20 from '../../icons/erc20.svg';
import NFT from '../../icons/nft.svg';
import Earth from '../../icons/earth.svg';
import Done from '../../icons/done.svg';
import Input from '../Input';
import GradientText from '../GradientText';
import Button from '../Button';
import FullScreenSpinner from '../FullScreenSpinner';
import { getFileFee, getUpload } from '../../api/file';
import { handleLogin } from '../../utils/web3-connector';
import UserContext from '../../context/UserContext';
import TextArea from '../TextArea';
import Spinner from '../Spinner';
import { enableUploadSign } from '../../utils/file';
import CheckTransactionStatusModal from '../CheckTransactionStatusModal';
import PageWrapper from '../PageWrapper';
import File from '../../icons/file.svg';
import SwitchButton from '../SwitchButton';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: '#FFFFFF',
  },
  header: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#FFFFFF',
  },
  subHeader: {
    marginTop: 60,
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '58px',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    background: '#020627',
    borderRadius: 30,
    padding: 40,
  },
  contentSecond: {
    marginTop: 50,
  },
  sectionHeader: {
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    marginTop: 72,
  },
  inputHeader: {
    display: 'flex',
    columnGap: 15,
    alignItems: 'center',
    marginTop: 48,
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 17,
    lineHeight: '26px',
    color: '#FFFFFF',
  },
  inputHeaderLast: {
    marginTop: 24,
  },
  inputHeaderText: {
    display: 'flex',
    columnGap: 5,
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  input: {
    maxWidth: 450,
    width: 450,
    marginTop: 24,
  },
  amountInput: {
    maxWidth: 270,
    width: 270,
    marginTop: 24,
  },
  erc20Container: {
    display: 'flex',
    columnGap: 20,
  },
  line: {
    height: 1,
    width: '100%',
    background: 'rgba(255, 255, 255, 0.3)',
    margin: '40px 0 23px 0',
  },
  blockRows: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid rgba(255, 255, 255, 0.1)',
    marginTop: 32,
  },
  blockRow: {
    columnGap: 40,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '17px 0',
  },
  blockRowLast: {
    borderBottom: 'none',
  },
  blockLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '22px',
    color: '#DDD6ED',
    flex: 1,
  },
  blockValue: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '22px',
    color: '#FFFFFF',
    flex: 4,
  },
  fileLinkRow: {
    width: 'fit-content',
    flex: 'none',
    padding: 16,
    border: '1px solid rgba(221, 214, 237, 0.5)',
    borderRadius: 12,
    columnGap: 15,
    background: 'rgba(2, 6, 39, 0.5)',

    '& div': {
      '& span': {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: '24px',
        letterSpacing: '0.005em',
      },
    },
  },
  copySign: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(221, 214, 237, 0.5)',

    '&:hover': {
      '& g': {
        opacity: 1,

        '& path': {
          stroke: '#8B55FF',
        },
      },
    },
  },
  signButtonContainer: {
    display: 'flex',
    width: '100%',
    columnGap: 32,
  },
  signButton: {
    fontWeight: 800,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 30,
  },
  estimatedGas: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#FFFFFF',
    margin: '45px 0 15px 0',
    display: 'flex',
    columnGap: 10,
  },
  icons: {
    display: 'flex',
    columnGap: 16,
  },
  optional: {
    color: '#DDD6ED',
  },
  transactionSummaryHeader: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#FFFFFF',
    marginTop: 80,
  },
});

const isValidAddress = (address) => {
  if (!address) {
    return true;
  }
  if (address?.includes(',')) {
    return address.split(',').map((addr) => addr.trim()).every(
      (addr) => (!addr || (/^(0x)?[0-9A-Fa-f]{40}$/.test(addr))),
    );
  }
  return (/^(0x)?[0-9A-Fa-f]{40}$/.test(address));
};

const EnableChainbackSign = () => {
  const styles = useStyles();
  const { id, isFromExplorer, isFromMain } = useParams();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [upload, setUpload] = useState(null);
  const [waitTransactionModal, setWaitTransactionModal] = useState();
  const [data, setData] = useState({});
  const [estimatedFeeArchive, setEstimatedFeeArchive] = useState();
  const [description, setDescription] = useState('');
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
  const [enableSignatures, setEnableSignatures] = useState(false);

  useEffect(() => {
    getUpload(id).then(setUpload);
    getFileFee().then(setEstimatedFeeArchive);
  }, []);

  let backLink = `/uploads/${id}`;
  if (isFromExplorer || isFromMain) {
    backLink += '?';
    if (isFromExplorer) {
      backLink += 'isFromExplorer=true&';
    }
    if (isFromMain) {
      backLink += 'isFromMain=true';
    }
  }

  if (upload?.isSignEnabled && upload?.isTransactionCommitted) {
    navigate(backLink);
    return null;
  }

  const visibility = [];
  if (data.erc20TokenAddresses) {
    // eslint-disable-next-line max-len
    visibility.push(
      <ERC20
        data-tooltip-id="restriction-tooltip"
        data-tooltip-content="Download only for owners of ERC20 tokens"
      />,
    );
  }
  if (data.nftTokenAddresses) {
    // eslint-disable-next-line max-len
    visibility.push(
      <NFT
        data-tooltip-id="restriction-tooltip"
        data-tooltip-content="Download only for owners of NFT"
      />,
    );
  }
  if (data.ethAddresses) {
    // eslint-disable-next-line max-len
    visibility.push(
      <Ethereum
        data-tooltip-id="restriction-tooltip"
        data-tooltip-content="Download only for specific addresses"
      />,
    );
  }
  if (data.visibleForSignatureOwners) {
    visibility.push('SIGNATURE OWNERS');
  }
  if (!visibility.length) {
    visibility.push(<Earth data-tooltip-id="restriction-tooltip" data-tooltip-content="Public"/>);
  }

  const signature = [];
  if (data.signErc20TokenAddresses) {
    // eslint-disable-next-line max-len
    signature.push(
      <ERC20
        data-tooltip-id="restriction-tooltip"
        data-tooltip-content="Sign only for owners of ERC20 tokens"
      />,
    );
  }
  if (data.signNftTokenAddresses) {
    signature.push(<NFT data-tooltip-id="restriction-tooltip" data-tooltip-content="Sign only for owners of NFT"/>);
  }
  if (data.signEthAddresses) {
    // eslint-disable-next-line max-len
    signature.push(
      <Ethereum
        data-tooltip-id="restriction-tooltip"
        data-tooltip-content="Sign only from specific addresses"
      />,
    );
  }
  if (!signature.length) {
    signature.push(<Earth data-tooltip-id="restriction-tooltip" data-tooltip-content="Public"/>);
  }

  const onConfirm = (walletId) => {
    const params = {
      description,
      visibleForSignatureOwners: data.visibleForSignatureOwners,
    };
    if (data.erc20TokensAddressesVisibility) {
      params.erc20TokenAddresses = data.erc20TokenAddresses?.trim();
      params.erc20TokenAddressMinAmount = parseFloat(data.erc20TokenAddressMinAmount);
    }
    if (enableSignatures && data.signErc20TokensAddressesVisibility) {
      params.signErc20TokenAddresses = data.signErc20TokenAddresses?.trim();
      params.signErc20TokenAddressMinAmount = parseFloat(data.signErc20TokenAddressMinAmount);
    }
    if (data.specificAddressesVisibility) {
      params.ethAddresses = data.ethAddresses;
    }
    if (data.nftTokensVisibility) {
      params.nftTokenAddresses = data.nftTokenAddresses?.trim();
    }
    if (enableSignatures && data.signSpecificAddressesVisibility) {
      params.signEthAddresses = data.signEthAddresses?.trim();
    }
    if (enableSignatures && data.signNftTokensVisibility) {
      params.signNftTokenAddresses = data.signNftTokenAddresses?.trim();
    }
    return enableUploadSign(
      upload.id,
      upload.ipfsHash,
      params,
      walletId || user?.walletId,
    ).then(setWaitTransactionModal).catch(() => {
    });
  };

  return (
    <PageWrapper
      header="Set Up Advanced File Access"
      linksChain={[
        { value: '/', label: 'Chainback' },
        (
          // eslint-disable-next-line react/destructuring-assignment
          (isFromExplorer || isFromMain || !user || !upload || (upload.userId !== user.id)) ?
            (isFromExplorer ? { value: '/explorer', label: 'Chainback Explorer' } : null) :
            { value: '/uploads', label: 'My Uploads' }
        ),
        (id === 'processing') ?
          null :
          {
            // eslint-disable-next-line max-len
            value: backLink,
            label: `${id.substring(0, 10)}****${id.substring(id.length - 10)}`,
          },
        {
          value: '',
          label: 'Set Up Advanced File Access',
        },
      ]}
    >
      <div className={styles.container}>
        {isTransactionInProgress ? <FullScreenSpinner/> : null}
        {
          waitTransactionModal ?
            (
              <CheckTransactionStatusModal
                transactionHash={waitTransactionModal}
                onClose={() => navigate(backLink)}
              />
            ) :
            null
        }
        {
          upload ?
            (
              <>
                <div className={styles.content}>
                  <div className={styles.header}>
                    File Overview
                  </div>
                  <div className={styles.blockRows}>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        File Name
                      </div>
                      <div className={styles.blockValue}>
                        <File/>
                        &nbsp;
                        {upload.fileName}
                      </div>
                    </div>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        Chainback Hash
                      </div>
                      <div className={styles.blockValue}>
                        {upload.id}
                      </div>
                    </div>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        IPFS Hash
                      </div>
                      <div className={styles.blockValue}>
                        {upload.ipfsHash}
                      </div>
                    </div>
                    <div className={classNames(styles.blockRow, styles.blockRowLast)}>
                      <div className={styles.blockLabel}>
                        File link
                      </div>
                      <div className={styles.blockValue}>
                        <div className={classNames(styles.blockValue, styles.fileLinkRow)}>
                          <div>
                            <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">
                              {`${window.location.origin}/uploads/${upload.id}`}
                            </GradientText>
                          </div>
                          <div>
                            <Copy
                              data-tooltip-id="copy-tooltip"
                              className={styles.copySign}
                              onClick={
                                () => navigator.clipboard.writeText(
                                  `${window.location.origin}/uploads/${upload.id}`,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.content, styles.contentSecond)}>
                  <div className={styles.header}>
                    Setup
                  </div>
                  <div className={styles.subHeader}>
                    Download Access Settings
                  </div>
                  {/* <CheckBox */}
                  {/*  label="File visible only to  addresses with file signature" */}
                  {/*  value={data.visibleForSignatureOwners} */}
                  {/*  onChange={(visibleForSignatureOwners) => setData({ ...data, visibleForSignatureOwners })} */}
                  {/* /> */}
                  <div className={styles.inputHeader}>
                    <ERC20/>
                    <div className={styles.inputHeaderText}>
                      Download only for owners of ERC20 tokens
                      <div className={styles.optional}>(optional)</div>
                    </div>
                    {data.erc20TokensAddressesVisibility ? <Done/> : null}
                  </div>
                  <div className={styles.erc20Container}>
                    <Input
                      error={!isValidAddress(data.erc20TokenAddresses)}
                      className={styles.input}
                      isRequired={true}
                      label="ERC20 token address"
                      value={data.erc20TokenAddresses}
                      onChange={(erc20TokenAddresses) => setData({
                        ...data,
                        erc20TokensAddressesVisibility: !!erc20TokenAddresses?.trim(),
                        erc20TokenAddresses,
                      })}
                    />
                    <Input
                      className={styles.amountInput}
                      isRequired={true}
                      label="Min Amount"
                      isNumeric={true}
                      placeHolder="0.000"
                      value={data.erc20TokenAddressMinAmount}
                      onChange={(erc20TokenAddressMinAmount) => setData({ ...data, erc20TokenAddressMinAmount })}
                    />
                  </div>
                  <div className={styles.inputHeader}>
                    <NFT/>
                    <div className={styles.inputHeaderText}>
                      Download only for owners of NFT
                      <div className={styles.optional}>(optional)</div>
                    </div>
                    {data.nftTokensVisibility ? <Done/> : null}
                  </div>
                  <Input
                    className={styles.input}
                    label="NFT token address"
                    isRequired={true}
                    error={!isValidAddress(data.nftTokenAddresses)}
                    value={data.nftTokenAddresses}
                    onChange={(nftTokenAddresses) => setData({
                      ...data,
                      nftTokensVisibility: !!nftTokenAddresses?.trim(),
                      nftTokenAddresses,
                    })}
                  />

                  <div className={styles.inputHeader}>
                    <Ethereum/>
                    <div className={styles.inputHeaderText}>
                      Download only for specific addresses
                      <div className={styles.optional}>(optional)</div>
                    </div>
                    {data.specificAddressesVisibility ? <Done/> : null}
                  </div>
                  <Input
                    className={styles.input}
                    label="ETH addresses separated by comma"
                    placeHolder="e.g: address, address"
                    isRequired={true}
                    error={!isValidAddress(data.ethAddresses)}
                    value={data.ethAddresses}
                    onChange={(ethAddresses) => setData({
                      ...data,
                      specificAddressesVisibility: !!ethAddresses?.trim(),
                      ethAddresses,
                    })}
                  />

                  <div className={styles.inputHeader}>
                    <div className={classNames(styles.inputHeaderText, styles.alignItemsCenter)}>
                      <span>Enable Signatures</span>
                      &nbsp;
                      &nbsp;
                      <SwitchButton value={enableSignatures} onChange={setEnableSignatures}/>
                    </div>
                  </div>
                  {
                    enableSignatures &&
                    <>
                      <div className={styles.subHeader}>
                        Signature Access Settings
                      </div>
                      <div className={styles.inputHeader}>
                        <ERC20/>
                        <div className={styles.inputHeaderText}>
                          Sign only for owners of ERC20 tokens
                          <div className={styles.optional}>(optional)</div>
                        </div>
                        {data.signErc20TokensAddressesVisibility ? <Done/> : null}
                      </div>
                      <div className={styles.erc20Container}>
                        <Input
                            className={styles.input}
                            label="ERC20 token address"
                            isRequired={true}
                            error={!isValidAddress(data.signErc20TokenAddresses)}
                            value={data.signErc20TokenAddresses}
                            onChange={(signErc20TokenAddresses) => setData({
                              ...data,
                              signErc20TokensAddressesVisibility: !!signErc20TokenAddresses?.trim(),
                              signErc20TokenAddresses,
                            })}
                        />
                        <Input
                            className={styles.amountInput}
                            label="Min Amount"
                            isNumeric={true}
                            placeHolder="0.000"
                            isRequired={true}
                            value={data.signErc20TokenAddressMinAmount}
                            onChange={(signErc20TokenAddressMinAmount) => setData({
                              ...data,
                              signErc20TokenAddressMinAmount,
                            })}
                        />
                      </div>
                      <div className={styles.inputHeader}>
                        <NFT/>
                        <div className={styles.inputHeaderText}>
                          Sign only for owners of NFT
                          <div className={styles.optional}>(optional)</div>
                        </div>
                        {data.signNftTokensVisibility ? <Done/> : null}
                      </div>
                      <Input
                          className={styles.input}
                          label="NFT token address"
                          isRequired={true}
                          error={!isValidAddress(data.signNftTokenAddresses)}
                          value={data.signNftTokenAddresses}
                          onChange={(signNftTokenAddresses) => setData({
                            ...data,
                            signNftTokensVisibility: !!signNftTokenAddresses?.trim(),
                            signNftTokenAddresses,
                          })}
                      />
                      <div className={styles.inputHeader}>
                        <Ethereum/>
                        <div className={styles.inputHeaderText}>
                          Sign only from specific addresses
                          <div className={styles.optional}>(optional)</div>
                        </div>
                        {data.signSpecificAddressesVisibility ? <Done/> : null}
                      </div>
                      <Input
                          className={styles.input}
                          label="ETH addresses separated by comma"
                          placeHolder="e.g: address, address"
                          isRequired={true}
                          error={!isValidAddress(data.signEthAddresses)}
                          value={data.signEthAddresses}
                          onChange={(signEthAddresses) => setData({
                            ...data,
                            signSpecificAddressesVisibility: !!signEthAddresses?.trim(),
                            signEthAddresses,
                          })}
                      />
                      <div className={styles.line}/>
                    </>
                  }
                  <div className={classNames(styles.inputHeader, styles.inputHeaderLast)}>
                    <div className={styles.inputHeaderText}>
                      Explorer File Description
                      <div className={styles.optional}>(optional)</div>
                    </div>
                  </div>
                  <TextArea
                    rows={1}
                    label="Description"
                    className={styles.input}
                    isRequired={true}
                    value={description}
                    onChange={(value) => setDescription(value)}
                    max={200}
                    showRemaining={true}
                  />
                  <div className={styles.transactionSummaryHeader}>
                    Setup Transaction Summary
                  </div>
                  <div className={styles.blockRows}>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        File Name
                      </div>
                      <div className={styles.blockValue}>
                        <File/>
                        &nbsp;
                        {upload.fileName}
                      </div>
                    </div>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        Chainback Hash
                      </div>
                      <div className={styles.blockValue}>
                        {upload.id}
                      </div>
                    </div>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        IPFS Hash
                      </div>
                      <div className={styles.blockValue}>
                        {upload.ipfsHash}
                      </div>
                    </div>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        File link
                      </div>
                      <div className={styles.blockValue}>
                        <div className={classNames(styles.blockValue, styles.fileLinkRow)}>
                          <div>
                            <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">
                              {`${window.location.origin}/uploads/${upload.id}`}
                            </GradientText>
                          </div>
                          <div>
                            <Copy
                              data-tooltip-id="copy-tooltip"
                              className={styles.copySign}
                              onClick={
                                () => navigator.clipboard.writeText(
                                  `${window.location.origin}/uploads/${upload.id}`,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.blockRow}>
                      <div className={styles.blockLabel}>
                        Download
                      </div>
                      <div className={classNames(styles.blockValue, styles.icons)}>
                        {visibility.map((icon, index) => (<React.Fragment key={index}>{icon}</React.Fragment>))}
                      </div>
                    </div>
                    {
                      enableSignatures && <div className={styles.blockRow}>
                        <div className={styles.blockLabel}>
                          Signature
                        </div>
                        <div className={classNames(styles.blockValue, styles.icons)}>
                          {signature.map((icon, index) => (
                              <React.Fragment key={index}>{icon}</React.Fragment>))}
                        </div>
                      </div>
                    }
                    {
                      upload.description ?
                        (
                          <div className={styles.blockRow}>
                            <div className={styles.blockLabel}>
                              File Description
                            </div>
                            <div className={classNames(styles.blockValue, styles.icons)}>
                              {upload.description}
                            </div>
                          </div>
                        ) :
                        null
                    }
                  </div>
                  <div className={styles.footer}>
                    <div className={styles.estimatedGas}>
                      <div>
                        {(estimatedFeeArchive || (estimatedFeeArchive === 0)) ? estimatedFeeArchive : '-'}
                      </div>
                      <div>
                        <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">
                          ARCHIVE
                        </GradientText>
                      </div>
                    </div>
                    <div className={styles.signButtonContainer}>
                      <Button
                        size="lg"
                        className={styles.signButton}
                        disabled={
                          (!estimatedFeeArchive && (estimatedFeeArchive !== 0)) ||
                          (
                            !isValidAddress(data.erc20TokenAddresses?.trim()) ||
                            !isValidAddress(data.signErc20TokenAddresses?.trim()) ||
                            !isValidAddress(data.ethAddresses?.trim()) ||
                            !isValidAddress(data.signEthAddresses?.trim()) ||
                            !isValidAddress(data.nftTokenAddresses?.trim()) ||
                            !isValidAddress(data.signNftTokenAddresses?.trim())
                          )
                        }
                        type="reversePrimary"
                        onClick={() => {
                          const confirm = (walletId) => {
                            setIsTransactionInProgress(true);
                            return onConfirm(walletId)
                              .then(() => setIsTransactionInProgress(false))
                              .catch(() => setIsTransactionInProgress(false));
                          };
                          if (user) {
                            return confirm();
                          }
                          return handleLogin().then((walletId) => getFileFee().then((feeData) => {
                            setEstimatedFeeArchive(feeData);
                            return confirm(walletId);
                          }));
                        }}
                      >
                        Setup
                      </Button>
                      <Button
                        size="lg"
                        className={styles.signButton}
                        onClick={() => window.open(
                          // eslint-disable-next-line max-len
                          `https://app.uniswap.org/#/swap?&chain=mainnet&use=v2&outputCurrency=${window.BLOCKCHAIN_ARCHIVE_CONTRACT}`,
                          '_blank',
                        )}
                      >
                        Buy $ARCHIVE
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) :
            (
              <div className={styles.spinnerContainer}>
                <Spinner/>
              </div>
            )
        }
      </div>
    </PageWrapper>
  );
};

export default EnableChainbackSign;
