import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useDropzone } from 'react-dropzone';
import { Upload, AddOne, ReduceOne } from '@icon-park/react';
import CopyLink from '../../icons/copy-link.svg';
import SignOk from '../../icons/sign-ok.svg';
import PaidFeatures from '../../icons/paid-features.svg';
import ShortAnswer1 from '../../icons/shortAnswer1.png';
import ShortAnswer2 from '../../icons/shortAnswer2.png';
import ShortAnswer3 from '../../icons/shortAnswer3.png';
import BlurRed from '../../icons/blur-red.webp';
import BlurBlue from '../../icons/blur-blue.webp';
import GradientText from '../GradientText';
import Button from '../Button';
import Transactions from './Transactions';
import UploadFileOptions from '../UploadFileOptions';
import FullScreenSpinner from '../FullScreenSpinner';
import ErrorModal from '../ErrorModal';
import GradientCircle from '../GradientCircle';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 120,
  },
  containerPaddings: {
    maxWidth: 1440,
    margin: '100px auto',
    marginBottom: 5,
    '@media (max-width: 1440px)': {
      padding: '0 34px',
    },
  },
  containerPaddings0: {
    maxWidth: 1440,
    margin: '0 auto',
    '@media (max-width: 1440px)': {
      padding: '0 34px',
    },
  },
  dropBlock: {
    display: 'flex',
    columnGap: 60,
    zIndex: 1,

    '& > div': {
      flex: 1,
    },
  },
  dropDescription: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 82,
    lineHeight: '105px',
    letterSpacing: '0.02em',
    zIndex: 999,

  },
  dropDescriptionSmall: {
    color: '#FFFFFF',
    fontSize: 22,
    lineHeight: '32px',
    width: '95%',
    zIndex: 999,
  },
  dropZone: {
    borderRadius: 50,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    position: 'relative',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    border: '3.5px dashed #13173F',
    background: 'linear-gradient(#10143B 0 0) padding-box, linear-gradient(90deg, #FA26CA 0%, #712AF9 100%) border-box',
    backgroundClip: 'padding-box, border-box',
    outline: '1px solid #13173F',
    boxSizing: 'border-box',
    marginRight: 75,
    height: 354,

    '&:hover': {
      // eslint-disable-next-line max-len
      background: 'linear-gradient(#070B2E 0 0) padding-box, linear-gradient(90deg, #FA26CA 0%, #712AF9 100%) border-box',
    },
  },
  dropZoneDescription: {
    fontWeight: 500,
    fontSize: 22,
    zIndex: 999,
  },
  questionsBlock: {},
  questionsBlockContainer: {
    marginTop: '70px !important',
    margin: '0 75px',
    padding: '130px 60px',
    background: 'linear-gradient(125deg, #282E5C 0%, #020627 100%)',
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 100,
  },
  questionBlock: {
    display: 'flex',
    rowGap: 5,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  questionTextQuestion: {
    fontFamily: 'Jost',
    fontWeight: 700,
    fontSize: 64,
  },
  questionTextHeader: {
    textAlign: 'center',
    fontWeight: 700,
    fontSize: 24,
    fontFamily: 'Jost',
    color: '#FFFFFF',
  },
  questionText: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    textAlign: 'center',
    lineHeight: '58px',
    color: '#FFFFFF',
  },
  transactionsBlock: {
    background: 'rgb(19, 23, 63)',

    '@media (max-width: 1440px)': {
      padding: '0 34px',
    },
  },
  transactionsBlockHeaderColored: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    marginTop: 16,
  },
  transactionsBlockHeader: {
    marginTop: 24,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    textAlign: 'center',
  },
  transactionsBlockContainer: {
    position: 'relative',
    marginTop: '70px !important',
    margin: '40px auto',
    background: 'linear-gradient(125deg, #282E5C 0%, #020627 100%)',
    borderRadius: 30,
    paddingBottom: 5,
    zIndex: 1,
  },
  shortAnswerBlock: {
    background: 'rgb(19, 23, 63)',

    '@media (max-width: 1440px)': {
      padding: '0 34px',
    },
  },
  shortAnswerHeaderColored: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    marginTop: 16,
  },
  shortAnswerBlockHeader: {
    marginTop: 24,
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    textAlign: 'center',
  },
  shortAnswerBlockContainer: {
    marginTop: '70px !important',
    margin: '40px auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 100,
  },
  transactionsBlockContainerButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 25,
    marginLeft: 0,
    marginRight: 0,
  },
  transactionsBlockContainerButton: {
    fontWeight: 700,
    margin: '-32px 0 32px 0',
  },
  useCaseBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: 1440,
  },
  useCaseHeader: {
    fontWeight: 700,
    letterSpacing: '0.1em',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '23px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  useCaseListHeader: {
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    textAlign: 'center',
    color: '#F8F8FF',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    marginTop: 24,
  },
  faqBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 800,
  },
  faqHeader: {
    fontWeight: 700,
    letterSpacing: '0.1em',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: '23px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  faqListHeader: {
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    textAlign: 'center',
    color: '#F8F8FF',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    marginTop: 24,
  },
  faqListText: {
    fontWeight: 400,
    lineHeight: '32px',
    fontSize: 22,
    textAlign: 'center',
    marginTop: 40,
  },
  faqListItems: {
    marginTop: 50,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  faqListItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    padding: '20px 0',
    borderBottom: '2px solid rgba(119, 128, 161, 0.5)',
  },
  faqListItemHeader: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#FFFFFF',
    fontFamily: 'Jost',
    fontStyle: 'normal',

    '&:hover': {
      color: '#8842FE',

      '& > span': {
        color: '#8842FE',
      },
    },
  },
  faqListItemText: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '32px',
    color: '#DDD6ED',
  },
  icon: {
    cursor: 'pointer',
    color: '#7780A1',

    '&:hover': {
      color: '#8842FE',
    },
  },
  mainDescription: {
    position: 'relative',
    marginLeft: 75,
  },
  uploadFileButton: {
    width: 270,
    height: 74,
    fontFamily: 'Jost',
    fontWeight: 800,
    fontSize: 24,
    lineHeight: '35px',
    zIndex: 999,
  },
  fullScreenDrug: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999999,
    background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
  },
  fullScreenDrugTextContainer: {
    color: '#FFFFFF',
    fontSize: 80,
    maxHeight: '50%',
    maxWidth: '50%',
  },
  dropFilePrimary: {
    fontFamily: 'Jost',
    fontSize: 70,
    fontStyle: 'normal',
    lineHeight: '100px',
    fontWeight: 700,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  dropFileSecondary: {
    fontFamily: 'Jost',
    fontSize: 32,
    fontStyle: 'normal',
    lineHeight: '100px',
    fontWeight: 700,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  chainbackExplorerTexts: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 35,
    maxWidth: 1017,
    margin: '40px auto',

    '& span': {
      fontFamily: 'Jost',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 22,
      lineHeight: '32px',
      textAlign: 'center',
      color: '#DDD6ED',
    },
  },
  shortAnswerItemBlock: {
    display: 'flex',
    columnGap: 80,
  },
  shortAnswerItemDescriptionBlock: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 40,
    width: '50%',
  },
  shortAnswerItemPictureBlock: {
    display: 'flex',
    justifyContent: 'right',
    width: '50%',
    maxWidth: 700,
  },
  shortAnswerItemDescriptionBlockHeader: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 50,
    lineHeight: '72px',
    color: '#FFFFFF',
  },
  shortAnswerItemDescriptionBlockText: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '32px',
    color: '#DDD6ED',
  },
  shortAnswerItemDescriptionNumberBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
    borderRadius: 100,
    backgroundColor: 'blue',
    color: '#FFFFFF',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '58px',
    textAlign: 'center',
  },
  topTextBlurRed: {
    '-webkit-animation': 'off-on 5s infinite linear',
    animation: 'off-on 5s infinite linear',
    zIndex: -1,
    position: 'absolute',
    top: -180,
    left: -140,
  },
  dropZoneBlurBlue: {
    '-webkit-animation': 'off-on 5s infinite linear',
    animation: 'off-on 5s infinite linear',
    zIndex: 0,
    position: 'absolute',
    top: -180,
    right: -140,
    opacity: 0.5,
  },
  transactionsBlurBlue: {
    '-webkit-animation': 'off-on 5s infinite linear',
    animation: 'off-on 5s infinite linear',
    zIndex: 0,
    position: 'absolute',
    top: 3220,
    left: '50%',
    opacity: 0.5,
  },
  transactionsTextBlurBlue: {
    '-webkit-animation': 'off-on-half 5s infinite linear',
    animation: 'off-on 5s infinite linear',
    zIndex: 0,
    position: 'absolute',
    top: -220,
    left: -220,
    opacity: 0.5,
  },
});


const renderFaqItem = (key, header, text, faqState, setFaqState, styles) => (
  <div className={styles.faqListItem}>
    <div
      className={styles.faqListItemHeader}
      onClick={() => setFaqState({ ...faqState, [key]: !faqState[key] })}
    >
      <div>
        {header}
      </div>
      {
        faqState[key] ? (
          <ReduceOne
            className={styles.icon}
            fill="#8842FE"
            size={24}
          />
        ) : (
          <AddOne className={styles.icon} size={24}/>
        )
      }
    </div>
    {
      faqState[key] ?
        (
          <div className={styles.faqListItemText}>
            {text}
          </div>
        ) :
        null
    }
  </div>
);

let SAMPLE_IMAGE_WIDTH;
if (window.innerWidth > 1250) {
  const HALF_SCREEN_WIDTH = window.innerWidth / 2 - 35;
  SAMPLE_IMAGE_WIDTH = (HALF_SCREEN_WIDTH > 700) ? 700 : HALF_SCREEN_WIDTH;
} else {
  SAMPLE_IMAGE_WIDTH = 500;
}

const renderShortAnswer = (number, header, text1, text2, pictureSrc, styles) => (
  <div className={styles.shortAnswerItemBlock}>
    <div className={styles.shortAnswerItemDescriptionBlock}>
      <div className={styles.shortAnswerItemDescriptionNumberBlock}>
        <GradientCircle>
          {number}
        </GradientCircle>
      </div>
      <div className={styles.shortAnswerItemDescriptionBlockHeader}>
        {header}
      </div>
      <div className={styles.shortAnswerItemDescriptionBlockText}>
        {text1}
      </div>
      <div className={styles.shortAnswerItemDescriptionBlockText}>
        {text2}
      </div>
    </div>
    <div className={styles.shortAnswerItemPictureBlock}>
      <img width={`${SAMPLE_IMAGE_WIDTH}px`} src={pictureSrc}/>
    </div>
  </div>
);


const MainPage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [faqState, setFaqState] = useState({});
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);
  const [isDrugging, setIsDrugging] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const onDrop = useCallback(([f]) => {
    setIsUploadingInProgress(true);
    setFile(f);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className={styles.container}
      onDragOver={() => {
        if (!isDrugging) {
          setIsDrugging(true);
        }
      }}
    >
      {
        isDrugging ?
          (
            <div
              className={styles.fullScreenDrug}
              onDrop={(e) => {
                if (e.dataTransfer?.files?.length) {
                  e.preventDefault();
                  setFile(e.dataTransfer.files[0]);
                  setIsUploadingInProgress(true);
                }
                setIsDrugging(false);
              }}
              onDragLeave={(e) => {
                if (
                  isDrugging &&
                  !e.target?.className?.includes(styles.dropFilePrimary) &&
                  !e.target?.className?.includes(styles.dropFileSecondary) &&
                  !e.relatedTarget?.className?.includes(styles.dropFilePrimary) &&
                  !e.relatedTarget?.className?.includes(styles.dropFileSecondary)
                ) {
                  setIsDrugging(false);
                }
              }}
            >
              <span className={styles.dropFilePrimary}>
                Drop file here
              </span>
              <p className={styles.dropFileSecondary}>
                1 file at a time
              </p>
            </div>
          ) :
          null
      }
      {isUploadingInProgress ? <FullScreenSpinner text={`${uploadingPercentage}%`} size={160}/> : null}
      <div className={classNames(styles.dropBlock, styles.containerPaddings)}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()}/>
        <div className={styles.mainDescription}>
          <img src={BlurRed} className={styles.topTextBlurRed}/>
          <GradientText className={styles.dropDescription} colorFrom="#46FF7A" colorMiddle="#52CBE7" colorTo="#7650DC">
            Upload and share files for Free
          </GradientText>
          <div className={styles.dropDescriptionSmall}>
            For additional exclusive content access control, select the Advanced File Access option
          </div>
        </div>
        {error ? <ErrorModal onClose={() => setError(null)} text={error}/> : null}
        {
          file ?
            (
              <UploadFileOptions
                onClose={(errorMessage) => {
                  setFile(null);
                  setUploadingPercentage(0);
                  setIsUploadingInProgress(false);
                  setError(errorMessage);
                }}
                file={file}
                progressCallback={setUploadingPercentage}
              />
            ) :
            null
        }
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div className={styles.dropZone} {...getRootProps()}>
          <img src={BlurBlue} className={styles.dropZoneBlurBlue}/>
          <Button
            className={styles.uploadFileButton}
            onClick={() => console.log('uploading')}
            isRounded={true}
            size="lg"
            type="primary"
          >
            <Upload size={24}/>
            &nbsp;
            Upload file
          </Button>
          <div className={styles.dropZoneDescription}>
            or drop a file
          </div>
        </div>
      </div>
      <div className={styles.shortAnswerBlock}>
        <div className={styles.shortAnswerHeaderColored}>
          <GradientText colorFrom="#46FF7A" colorMiddle="#52CBE7" colorTo="#863FFE">
            SHORT ANSWER
          </GradientText>
        </div>
        <div className={styles.shortAnswerBlockHeader}>
          How it works?
        </div>
        <div className={classNames(styles.shortAnswerBlockContainer, styles.containerPaddings0)}>
          {
            renderShortAnswer(
              1,
              'Upload file or drag and drop',
              // eslint-disable-next-line max-len
              'Simply tap the button to select the file you wish to upload, and in return, you\'ll receive a free link.',
              'Share the link with others to provide them with authorized access to your file.',
              ShortAnswer1,
              styles,
            )
          }
          {
            renderShortAnswer(
              2,
              'Enable Advanced File Access',
              // eslint-disable-next-line max-len
              'You can also choose to enable advanced file access, if you wish to gain full control over who can access your content.',
              // eslint-disable-next-line max-len
              'Click on “Enable Advanced File Access” and fill in a specific wallet, ERC20 token or NFT contract address.',
              ShortAnswer2,
              styles,
            )
          }
          {
            renderShortAnswer(
              3,
              'Complete a transaction',
              'To complete your access control setup, you will need 10,000 $Archive tokens.',
              // eslint-disable-next-line max-len
              'If you need to top up $Archive tokens, you can simply purchase them by clicking the “Buy $Archive” button.',
              ShortAnswer3,
              styles,
            )
          }
        </div>
      </div>
      <div className={styles.transactionsBlock}>
        <div className={styles.transactionsBlockHeaderColored}>
          <GradientText colorFrom="#46FF7A" colorMiddle="#52CBE7" colorTo="#863FFE">
            CHAINBACK EXPLORER
          </GradientText>
        </div>
        <div className={styles.transactionsBlockHeader}>
          Chainback Explorer
        </div>
        <div className={styles.chainbackExplorerTexts}>
          <img src={BlurBlue} className={styles.transactionsTextBlurBlue}/>
          {/* eslint-disable-next-line max-len */}
          <span>Chainback Explorer is an indispensable tool alowing users to track and monitor all transactions within the Chainback platform.</span>
          {/* eslint-disable-next-line max-len */}
          <span>With a focus on transparency and visibility, this  tool offers insights into crucial activities such as file uploads and signatures, enabling users to verify the integrity of their documents with ease.</span>
        </div>
        <img src={BlurBlue} className={styles.transactionsBlurBlue}/>
        <div className={classNames(styles.transactionsBlockContainer, styles.containerPaddings0)}>
          <Transactions/>
          <div className={styles.transactionsBlockContainerButtonContainer}>
            <Button
              className={styles.transactionsBlockContainerButton}
              type="link"
              onClick={() => navigate('/explorer')}
            >
              Go to ChainbackExplorer
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.useCaseBlock}>
        <GradientText
          className={styles.useCaseHeader}
          colorFrom="#46FF7A"
          colorMiddle="#52CBE7"
          colorTo="#7650DC"
        >
          USE CASE
        </GradientText>
        <div className={styles.useCaseListHeader}>
          What is your Chainback use case?
        </div>
        <div className={styles.questionsBlockContainer}>
          <div className={styles.questionBlock}>
            <CopyLink/>
            <div className={styles.questionText}>
              File Sharing by Leveraging IPFS
            </div>
          </div>
          <div className={styles.questionBlock}>
            <SignOk/>
            <div className={styles.questionText}>
              Advanced File Access Feature
            </div>
          </div>
          <div className={styles.questionBlock}>
            <PaidFeatures/>
            <div className={styles.questionText}>
              Chainback Paid Signatures
            </div>
          </div>
        </div>
      </div>
      <div className={classNames(styles.faqBlock, styles.containerPaddings0)}>
        <GradientText
          className={styles.faqHeader}
          colorFrom="#46FF7A"
          colorMiddle="#52CBE7"
          colorTo="#7650DC"
        >
          FAQ
        </GradientText>
        <div className={styles.faqListHeader}>
          What is Chainback?
        </div>
        <div className={styles.faqListText}>
          {/* eslint-disable-next-line max-len */}
          Chainback is a decentralized web3 file distributing platform that leverages blockchain technology to
          safeguard files from tampering or deletion, providing enhanced security and reliability.
        </div>
        <div className={styles.faqListItems}>
          {
            renderFaqItem(
              'howDoesChainbackWork',
              'How does Chainback work?',
              // eslint-disable-next-line max-len
              'Chainback disrupts traditional file storage with a decentralized approach. Files are encrypted, divided, and stored on multiple nodes using IPFS, ensuring constant availability and protection against tampering or deletion. This innovative method guarantees constant accessibility and protects your files from tampering or deletion.',
              faqState,
              setFaqState,
              styles,
            )
          }
          {
            renderFaqItem(
              'whatAreTheBenefits',
              'What are the benefits of using Chainback?',
              // eslint-disable-next-line max-len
              'Chainback surpasses traditional file storage solutions, delivering a range of advantages. Experience improved security, reliability, and transparency. Rest assured that your files will always be accessible and safeguarded.',
              faqState,
              setFaqState,
              styles,
            )
          }
          {
            renderFaqItem(
              'whatTypesOfFilesCanUpload',
              'What types of files can I upload?',
              // eslint-disable-next-line max-len
              'Chainback empowers you to securely store and share various types of files, including important documents, legal records, digital assets such as tokens and NFTs, and more. With Advanced File Access, you can take it a step further by uploading files and enabling multiple users to access and sign them through transactions.',
              faqState,
              setFaqState,
              styles,
            )
          }
          {
            renderFaqItem(
              'isChainbackSecure',
              'Is Chainback secure?',
              // eslint-disable-next-line max-len
              'Absolutely! Chainback prioritizes security at its core. Built on blockchain technology, Chainback ensures files are impervious to tampering or deletion. Every file undergoes encryption and is stored across multiple nodes, providing an unparalleled level of protection. Unauthorized access or modification is virtually impossible, guaranteeing the utmost security for your valuable data.',
              faqState,
              setFaqState,
              styles,
            )
          }
          {
            renderFaqItem(
              'whatIsIpfs',
              'What is IPFS?',
              // eslint-disable-next-line max-len
              'The InterPlanetary File System (IPFS) is a technology that enables decentralized file storage and sharing across a network of computers. It breaks files into small pieces, encrypts them, and distributes them across multiple computers, ensuring files are always available and cannot be easily altered or deleted.',
              faqState,
              setFaqState,
              styles,
            )
          }
        </div>
      </div>
    </div>
  );
};


export default MainPage;
