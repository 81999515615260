import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

const SwitchButton = ({ value, onChange }) => (
  <Switch
    checked={value}
    onChange={onChange}
  />
);

SwitchButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SwitchButton.defaultProps = {
  value: false,
};

export default SwitchButton;
