import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Copy from '../../icons/copy.svg';
import PageWrapper from '../PageWrapper';
import { getSignature, getUpload } from '../../api/file';
import SignFileModal from '../SignFileModal';
import ForbiddenModal from '../ForbiddenModal';
import CheckTransactionStatusModal from '../CheckTransactionStatusModal';
import UserContext from '../../context/UserContext';
import PrivateFile from '../../icons/private_file.svg';
import PublicFile from '../../icons/public_file.svg';
import File from '../../icons/file.svg';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 50,
  },
  overview: {
    display: 'flex',
    flexDirection: 'column',
    background: '#020627',
    borderRadius: 30,
    padding: 40,
  },
  blockHeader: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    padding: '19px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  blockRow: {
    columnGap: 40,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '19px 0',
  },
  blockLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 1,
  },
  blockValue: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 4,
  },
  copyRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    fontSize: 16,
    lineHeight: '23px',
  },
  linkRow: {
    cursor: 'pointer',
    color: '#DDD6ED',
    fontWeight: 700,

    '&:hover': {
      color: '#8B55FF',

      '& $copySign': {
        color: '#8B55FF',
      },
    },
  },
  copySign: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(221, 214, 237, 0.5)',

    '&:hover': {
      '& g': {
        opacity: 1,

        '& path': {
          stroke: '#8B55FF',
        },
      },
    },
  },
  buttons: {
    display: 'flex',
    columnGap: 20,
  },
});


const renderCopyRow = (label, value, styles, isLink) => (
  value ?
    (
      <div className={styles.copyRow}>
        <div
          className={isLink ? styles.linkRow : null}
          onClick={() => (isLink ? window.open(value, '_blank') : null)}
        >
          {label || value}
        </div>
        <div>
          <Copy
            data-tooltip-id="copy-tooltip"
            onClick={() => navigator.clipboard.writeText(label || value)}
            className={styles.copySign}
          />
        </div>
      </div>
    ) :
    null
);


const ExplorerSignatureDetailsPage = () => {
  const styles = useStyles();
  const user = useContext(UserContext);
  const { uploadId, signatureId } = useParams();
  const [upload, setUpload] = useState();
  const [signature, setSignature] = useState();
  const [showSignFileModal, setShowSignFileModal] = useState(false);
  const [showForbiddenModal, setShowForbiddenModal] = useState(false);
  const [waitTransactionModal, setWaitTransactionModal] = useState();
  const [searchParams] = useSearchParams();
  const isFromExplorer = searchParams.get('isFromExplorer');
  const isFromMain = searchParams.get('isFromMain');

  useEffect(() => {
    getSignature(uploadId, signatureId).then(
      (transaction) => setSignature({
        ...transaction,
        createdAt: new Date(transaction.createdAt),
      }),
    );
    getUpload(uploadId).then(setUpload);
  }, []);

  return (
    <PageWrapper
      header="Signature Transaction Details"
      linksChain={[
        { value: '/', label: 'Chainback' },
        (
          // eslint-disable-next-line react/destructuring-assignment
          (isFromExplorer || isFromMain || !user || !upload || (upload.userId !== user.id)) ?
            (isFromExplorer ? { value: '/explorer', label: 'Chainback Explorer' } : null) :
            { value: '/uploads', label: 'My Uploads' }
        ),
        {
          // eslint-disable-next-line max-len
          value: `/uploads/${uploadId}${isFromExplorer ? '?isFromExplorer=true' : ''}${isFromMain ? '?isFromMain=true' : ''}`,
          label: `${uploadId.substring(0, 10)}****${uploadId.substring(uploadId.length - 10)}`,
        },
        {
          // eslint-disable-next-line max-len
          value: `/uploads/${uploadId}/signatures/${signatureId}${isFromExplorer ? '?isFromExplorer=true' : ''}${isFromMain ? '?isFromMain=true' : ''}`,
          label: `${signatureId.substring(0, 10)}****${signatureId.substring(signatureId.length - 10)}`,
        },
      ]}
    >
      <div className={styles.container}>
        {showForbiddenModal ? <ForbiddenModal onClose={() => setShowForbiddenModal(false)}/> : null}
        {
          waitTransactionModal ?
            (
              <CheckTransactionStatusModal
                transactionHash={waitTransactionModal}
                onClose={() => setWaitTransactionModal(null)}
              />
            ) :
            null
        }
        {
          (signature && upload) ?
            (
              <div className={styles.overview}>
                {
                  showSignFileModal ?
                    (
                      <SignFileModal
                        onConfirm={(transactionHash) => {
                          if (transactionHash) {
                            setShowSignFileModal(false);
                            setWaitTransactionModal(transactionHash);
                          } else {
                            setShowSignFileModal(false);
                            setShowForbiddenModal(true);
                          }
                        }}
                        onClose={() => setShowSignFileModal(false)}
                        upload={upload}
                      />
                    ) :
                    null
                }
                <div className={styles.blockHeader}>Signature Overview</div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    ETH Transaction Hash
                  </div>
                  <div className={styles.blockValue}>
                    {
                      signature.ethereumHash ?
                        renderCopyRow(
                          signature.ethereumHash,
                          `${window.ETHERSCAN_URL}/tx/${signature.ethereumHash}`,
                          styles,
                          true,
                        ) :
                        '-'
                    }
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    Chainback Hash
                  </div>
                  <div className={styles.blockValue}>
                    {renderCopyRow(null, signature.id, styles)}
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    Block
                  </div>
                  <div className={styles.blockValue}>
                    {signature.block}
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    Timestamp
                  </div>
                  <div className={styles.blockValue}>
                    {(new Date((new Date()).getTime() - signature.createdAt.getTime())).formatRemainingTime()}
                  </div>
                </div>
                {/* <div className={styles.blockRow}> */}
                {/*  <div className={styles.blockLabel}> */}
                {/*    From */}
                {/*  </div> */}
                {/*  <div className={styles.blockValue}> */}
                {/*    { */}
                {/*      signature.from ? */}
                {/*        renderCopyRow( */}
                {/*          signature.from, */}
                {/*          `${window.ETHERSCAN_URL}/address/${signature.from}`, */}
                {/*          styles, */}
                {/*          true, */}
                {/*        ) : */}
                {/*        '-' */}
                {/*    } */}
                {/*  </div> */}
                {/* </div> */}
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    ARCHIVE fee
                  </div>
                  <div className={styles.blockValue}>
                    {signature.feeArchive || '-'}
                    &nbsp;
                    ARCHIVE
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    ETH fee
                  </div>
                  <div className={styles.blockValue}>
                    {signature.fee || '-'}
                    &nbsp;
                    ETH
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    Signer
                  </div>
                  <div className={styles.blockValue}>
                    {
                      signature.from ?
                        renderCopyRow(
                          signature.from,
                          `${window.ETHERSCAN_URL}/address/${signature.from}`,
                          styles,
                          true,
                        ) :
                        '-'
                    }
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    {'Signer\'s name'}
                  </div>
                  <div className={styles.blockValue}>
                    {signature.signerName}
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    {'Signer\'s comment'}
                  </div>
                  <div className={styles.blockValue}>
                    {signature.signerComment}
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    File Hash
                  </div>
                  <div className={styles.blockValue}>
                    {(upload.isPublic === false) ? <PrivateFile/> : <PublicFile/>}
                    {renderCopyRow(null, upload.id, styles)}
                  </div>
                </div>
                <div className={styles.blockRow}>
                  <div className={styles.blockLabel}>
                    File Name
                  </div>
                  <div className={styles.blockValue}>
                    <File/>
                    &nbsp;
                    {upload.fileName}
                  </div>
                </div>
              </div>
            ) :
            null
        }
      </div>
    </PageWrapper>
  );
};


export default ExplorerSignatureDetailsPage;
