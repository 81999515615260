export const msToTime = (ms) => {
  if (ms === null || ms === undefined) {
    return null;
  }
  let remainingMs = ms;
  let days;
  let hours;
  let minutes;
  let seconds;
  if (remainingMs) {
    days = Math.floor(remainingMs / 86400000);
    remainingMs -= days * 86400000;
  } else {
    days = 0;
  }
  if (remainingMs) {
    hours = Math.floor(remainingMs / 3600000);
    remainingMs -= hours * 3600000;
  } else {
    hours = 0;
  }
  if (remainingMs) {
    minutes = Math.floor(remainingMs / 60000);
    remainingMs -= minutes * 60000;
  } else {
    minutes = 0;
  }
  if (remainingMs) {
    seconds = Math.floor(remainingMs / 1000);
  } else {
    seconds = 0;
  }
  return {
    days: days ? days.toString() : undefined,
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: seconds.toString().padStart(2, '0'),
  };
};

export const msToTimeShort = (ms) => {
  if (ms === null || ms === undefined) {
    return null;
  }
  let remainingMs = ms;
  let minutes;
  let seconds;
  if (remainingMs) {
    minutes = Math.floor(remainingMs / 60000);
    remainingMs -= minutes * 60000;
  } else {
    minutes = 0;
  }
  if (remainingMs) {
    seconds = Math.floor(remainingMs / 1000);
  } else {
    seconds = 0;
  }
  return {
    minutes: minutes.toString().padStart(2, '0'),
    seconds: seconds.toString().padStart(2, '0'),
  };
};

export default { msToTime, msToTimeShort };
