import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uploadFile } from '../../utils/file';


const UploadFileOptions = ({ file, onClose, progressCallback }) => {
  const navigate = useNavigate();

  useEffect(() => {
    uploadFile(file, progressCallback).then(({ id }) => {
      navigate(`/uploads/${id}`);
      onClose();
    }).catch((err) => {
      if ((err?.name === 'HTTPError') && (err?.response?.status === 413)) {
        onClose('Failed to upload. File is too large.');
      } else {
        onClose('Failed to upload file.');
      }
    });
  }, []);

  return null;
};


UploadFileOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  progressCallback: PropTypes.func.isRequired,
};


export default UploadFileOptions;
