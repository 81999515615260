import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';


const useStyles = createUseStyles({
  container: {
    width: 'fit-content',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 100,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '17px',
    color: '#FFFFFF',
    height: 22,
    minWidth: 70,

    '& $border': {
      padding: '0 24px',
    },
  },
  border: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: 1,
    borderRadius: 50,
    border: '1px solid transparent',
    // eslint-disable-next-line max-len
    background: ({ backgroundColor }) => `linear-gradient(${backgroundColor || '#13173F'} 0 0) padding-box, linear-gradient(90deg, #DE63FF 0%, #46FF7A 100%) border-box`,
  },
});


const GradientLabel = ({ children, backgroundColor }) => {
  const styles = useStyles({ backgroundColor });

  return (
    <div className={styles.container}>
      <div className={styles.border}>
        {children}
      </div>
    </div>
  );
};


GradientLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  backgroundColor: PropTypes.string,
};


GradientLabel.defaultProps = {
  backgroundColor: null,
};


export default GradientLabel;
