import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import Copy from '../../icons/copy.svg';
import Modal from '../Modal';
import File from '../../icons/file.svg';
import GradientText from '../GradientText';
import Input from '../Input';
import Button from '../Button';
import { addSign } from '../../utils/file';
import UserContext from '../../context/UserContext';
import FullScreenSpinner from '../FullScreenSpinner';
import { getSignatureFee } from '../../api/file';
import { handleLogin } from '../../utils/web3-connector';
import TextArea from '../TextArea';
import PrivateFile from '../../icons/private_file.svg';
import PublicFile from '../../icons/public_file.svg';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 737,
  },
  headerFileNameRow: {
    display: 'flex',
    columnGap: 5,
  },
  headerFileName: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    color: '#FFFFFF',
  },
  header: {
    marginTop: 35,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '35px',
    color: '#FFFFFF',
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockRow: {
    columnGap: 40,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '19px 0',
  },
  blockLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 1,
  },
  blockValue: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 4,
  },
  copySign: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(221, 214, 237, 0.5)',

    '&:hover': {
      '& g': {
        opacity: 1,

        '& path': {
          stroke: '#8B55FF',
        },
      },
    },
  },
  fileLinkRow: {
    padding: 16,
    border: '1px solid rgba(221, 214, 237, 0.5)',
    borderRadius: 12,
    columnGap: 15,
    background: 'rgba(2, 6, 39, 0.5)',

    '& div': {
      '& span': {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: '24px',
        letterSpacing: '0.005em',
        fontStyle: 'normal',
      },
    },
  },
  signerDetails: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
    rowGap: 15,
  },
  signButton: {
    width: '100%',
    marginTop: 24,
  },
  input: {
    maxWidth: 355,
  },
  estimatedGas: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#FFFFFF',
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    columnGap: 10,
  },
});

const SignFileModal = ({ upload, onConfirm, onClose }) => {
  const styles = useStyles();
  const user = useContext(UserContext);
  const [name, setName] = useState(null);
  const [comment, setComment] = useState(null);
  const [estimatedFeeArchive, setEstimatedFeeArchive] = useState();
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);

  useEffect(() => {
    getSignatureFee().then(setEstimatedFeeArchive);
  }, []);

  return (
    <>
      {isTransactionInProgress ? <FullScreenSpinner/> : null}
      <Modal key="signFile" header="Sign File" onClose={() => onClose()} showClose={true}>
        <div className={styles.container}>
          <div className={styles.headerFileNameRow}>
            <File/>
            <div className={styles.headerFileName}>
              {upload.fileName}
            </div>
          </div>
          <div className={styles.header}>
            File Details
          </div>
          <div className={styles.fileInfo}>
            <div className={styles.blockRow}>
              <div className={styles.blockLabel}>
                Ethereum File Hash
              </div>
              <div className={styles.blockValue}>
                {upload.transaction?.ethereumHash}
              </div>
            </div>
            <div className={styles.blockRow}>
              <div className={styles.blockLabel}>
                IPFS File Hash
              </div>
              <div className={styles.blockValue}>
                {upload.ipfsHash}
              </div>
            </div>
            <div className={styles.blockRow}>
              <div className={styles.blockLabel}>
                Chainback File Hash
              </div>
              <div className={styles.blockValue}>
                {(upload.isPublic === false) ? <PrivateFile/> : <PublicFile/>}
                &nbsp;
                {upload.id}
              </div>
            </div>
            <div className={styles.blockRow}>
              <div className={styles.blockLabel}>
                File name
              </div>
              <div className={styles.blockValue}>
                <File/>
                &nbsp;
                {upload.fileName}
              </div>
            </div>
            <div className={styles.blockRow}>
              <div className={styles.blockLabel}>
                File link
              </div>
              <div className={styles.blockValue}>
                <div className={classNames(styles.blockValue, styles.fileLinkRow)}>
                  <div>
                    <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">
                      {`${window.location.origin}/uploads/${upload.id}`}
                    </GradientText>
                  </div>
                  <div>
                    <Copy
                      data-tooltip-id="copy-tooltip"
                      className={styles.copySign}
                      onClick={
                        () => navigator.clipboard.writeText(
                          `${window.location.origin}/uploads/${upload.id}`,
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.header}>
            Signer Details
          </div>
          <div className={styles.signerDetails}>
            <Input
              placeHolder="Type..."
              label="Name"
              className={styles.input}
              value={name}
              onChange={(value) => setName(value)}
              max={150}
            />
            <TextArea
              placeHolder="Type..."
              label="Comment"
              className={styles.input}
              value={comment}
              onChange={(value) => setComment(value)}
              max={200}
              showRemaining={true}
            />
          </div>
          <div className={styles.estimatedGas}>
            <div>
              {(estimatedFeeArchive || (estimatedFeeArchive === 0)) ? estimatedFeeArchive : '-'}
            </div>
            <div>
              <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">
                ARCHIVE
              </GradientText>
            </div>
          </div>
          <Button
            className={styles.signButton}
            type="reversePrimary"
            onClick={
              () => {
                setIsTransactionInProgress(true);
                const add = () => addSign(
                  upload.id,
                  upload.ipfsHash,
                  name,
                  comment,
                  user?.walletId,
                ).then((transactionHash) => {
                  setIsTransactionInProgress(false);
                  onConfirm(transactionHash);
                }).catch((err) => {
                  console.error('Failed to add sign', err);
                  setIsTransactionInProgress(false);
                  if (err?.response?.status === 403) {
                    onConfirm();
                  }
                });
                if (user) {
                  return add();
                }
                return handleLogin().then(add);
              }
            }
          >
            Sign
          </Button>
        </div>
      </Modal>
    </>
  );
};

SignFileModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  upload: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SignFileModal;
