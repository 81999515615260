import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { msToTime } from './utils/date';

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

Date.prototype.formatRemainingTime = function () {
  const { days, hours, minutes, seconds } = msToTime(this.getTime());
  let string = '';
  if (days && parseInt(days, 10)) {
    string += `${parseInt(days, 10)} days, `;
  }
  if (hours && (parseInt(hours, 10) || string.endsWith('days, '))) {
    string += `${parseInt(hours, 10)} hours, `;
  }
  if ((!days || !parseInt(days, 10)) && minutes && (parseInt(minutes, 10) || string.endsWith('hours, '))) {
    string += `${parseInt(minutes, 10)} min, `;
  }
  if ((!minutes || !parseInt(minutes, 10)) && seconds && (parseInt(seconds, 10) || string.endsWith('min, '))) {
    string += `${parseInt(seconds, 10)} sec`;
  }
  string = string.trim();
  if (string.endsWith(',')) {
    string = string.substring(0, string.length - 1);
  }
  if (!string) {
    return 'now';
  }
  return string;
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);
