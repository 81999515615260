import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import Pagination from 'rc-pagination';


const useStyles = createUseStyles({
  container: {
    width: '100%',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tr: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  td: {
    padding: '23.5px 8px 23.5px 0',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  },
  tdLeft: {
    paddingLeft: '0 !important',
  },
  tdRight: {
    paddingRight: '0 !important',
  },
  th: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '22px',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    color: '#FFFFFF',
    padding: '25px 8px',
  },
  tdBlockHeader: {
    color: '#FFFFFF',
  },
  tdBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tdBlockLeft: {
    justifyContent: 'left',
  },
  tdBlockCenter: {
    justifyContent: 'center',
  },
  tdBlockRight: {
    justifyContent: 'flex-end',
  },
});


const Table = ({ parentRef, columns, loadItems, maxItems, hidePagination }) => {
  const styles = useStyles();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  const load = (offs, filter) => loadItems(maxItems, offs || 0, filter).then((result) => {
    if (!offs || result.items.length) {
      setOffset(offs || 0);
      setTotal(result.total);
      setItems(result.items);
    } else {
      load(offs - maxItems);
    }
  });

  useEffect(() => {
    load();
  }, []);

  const refresh = (filter) => load(offset, filter);

  useImperativeHandle(parentRef, () => ({
    refresh,
  }), [offset]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tr}>
            {
              columns.map((column, index) => (
                <th
                  key={column.id || index}
                  className={classNames(styles.td, styles.th, styles[`tdBlock${column.align?.capitalize()}`])}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width }}
                >
                  <div
                    className={
                      classNames(
                        styles.tdBlockHeader,
                        styles.tdBlock,
                        styles[`tdBlock${column.align?.capitalize()}`],
                      )
                    }
                  >
                    {column.label || column.id}
                  </div>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            items.map((item) => (
              <tr key={item.id} className={styles.tr}>
                {
                  columns.map((column, index) => (
                    <td
                      key={`${item.id}-${(column.id || index)}`}
                      className={
                        classNames(
                          styles.td,
                          index ? null : styles.tdLeft,
                          (index === (columns.length - 1)) ? styles.tdRight : null,
                        )
                      }
                      style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width }}
                    >
                      <div
                        className={classNames(
                          styles.tdBlock,
                          styles[`tdBlock${column.align?.capitalize()}`],
                          column.className,
                        )}
                      >
                        {
                          (column.getValue ? column.getValue(item, refresh) : item[column.id]) ||
                          column.defaultValue ||
                          '-'
                        }
                      </div>
                    </td>
                  ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>
      {
        (!hidePagination && (total > maxItems)) ?
          (
            <Pagination
              showTitle={false}
              current={offset / maxItems + 1}
              total={total}
              onChange={(pageNumber) => load((pageNumber - 1) * maxItems)}
            />
          ) :
          null
      }
    </div>
  );
};


Table.propTypes = {
  parentRef: PropTypes.oneOfType([
    () => null,
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  columns: PropTypes.arrayOf({
    id: PropTypes.string,
    label: PropTypes.string,
    align: PropTypes.string,
    getValue: PropTypes.func,
  }).isRequired,
  loadItems: PropTypes.func.isRequired,
  maxItems: PropTypes.number,
  hidePagination: PropTypes.bool,
};


Table.defaultProps = {
  parentRef: null,
  maxItems: 10,
  hidePagination: false,
};


export default Table;
