import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../Button';
import { handleLogin } from '../../utils/web3-connector';

const useStyles = createUseStyles({
  container: {
    fontSize: 20,
    fontWeight: 700,
  },
});

const Login = () => {
  const styles = useStyles();
  return (
    <Button className={styles.container} type="reversePrimary" onClick={handleLogin}>
      Connect Wallet
    </Button>
  );
};

export default Login;
