import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import MainPage from './components/MainPage';
import ExplorerPage from './components/ExplorerPage';
import UploadsPage from './components/UploadsPage';
import ExplorerSignatureDetailsPage from './components/ExplorerSignatureDetailsPage';
import FileDetailsPage from './components/FileDetailsPage';
import UserProvider from './provider/UserProvider';
import EnableChainbackSign from './components/EnableChainbackSign';

const getLayoutComponent = (Component) => (
  <UserProvider>
    <Layout>
      <Component/>
    </Layout>
  </UserProvider>
);

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={getLayoutComponent(MainPage)}/>
      <Route path="/explorer" element={getLayoutComponent(ExplorerPage)}/>
      <Route
        path="/uploads/:uploadId/signatures/:signatureId"
        element={getLayoutComponent(ExplorerSignatureDetailsPage)}
      />
      <Route path="/uploads" element={getLayoutComponent(UploadsPage)}/>
      <Route path="/uploads/:id" element={getLayoutComponent(FileDetailsPage)}/>
      <Route path="/uploads/:id/enable" element={getLayoutComponent(EnableChainbackSign)}/>
      <Route path="/uploads/:id/download" element={getLayoutComponent(FileDetailsPage)}/>
    </Routes>
  </BrowserRouter>
);

export default App;
