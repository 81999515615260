import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import Attention from '../../icons/attention.svg';
import Modal from '../Modal';
import CheckOne from '../../icons/check-one.svg';
import Spinner from '../Spinner';
import { checkTransactionHash } from '../../utils/file';
import Button from '../Button';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 24,
    width: 500,
  },
  icon: {
    width: 94,
    height: 94,
    color: '#F5576E',
  },
  text: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '46px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
});

const CheckTransactionStatusModal = ({ onClose, transactionHash }) => {
  const styles = useStyles();
  const [isInProgress, setIsInProgress] = useState(true);
  const [result, setResult] = useState(false);

  useEffect(() => {
    let onKeyDown;
    if (onClose) {
      onKeyDown = (event) => {
        if (event.keyCode === 27) {
          onClose();
        }
      };
      document.addEventListener('keydown', onKeyDown);
    }
    checkTransactionHash(transactionHash).then((res) => {
      setResult(res);
      setIsInProgress(false);
    });
    return () => {
      if (onClose) {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, []);

  return (
    <Modal key="checkTransaction" onClose={() => !isInProgress && onClose()}>
      <div className={styles.container}>
        {(result && !isInProgress) ? <CheckOne className={styles.icon} onClick={() => onClose()}/> : null}
        {(!result && !isInProgress) ? <Attention className={styles.icon} size={94} onClick={() => onClose()}/> : null}
        {isInProgress ? <Spinner/> : null}
        <div className={styles.text}>
          {isInProgress ? 'Transaction is in progress...' : null}
          {(result && !isInProgress) ? 'Transaction processed' : null}
          {
            (!result && !isInProgress) ?
              'Transaction processing can take more time. Try to refresh the page later.' :
              null
          }
        </div>
        {
          isInProgress ?
            null :
            (
              <Button
                size="lg"
                type={
                  (result && !isInProgress) ?
                    'primary' :
                    ((!result && !isInProgress) ? 'reversePrimaryLight' : undefined)
                }
                onClick={onClose}
              >
                Ok
              </Button>
            )
        }
      </div>
    </Modal>
  );
};

CheckTransactionStatusModal.propTypes = {
  transactionHash: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CheckTransactionStatusModal;
