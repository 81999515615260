import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import Modal from '../Modal';
import CheckOne from '../../icons/check-one.svg';
import Button from '../Button';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 24,
  },
  icon: {
    cursor: 'pointer',
  },
  text: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '46px',
    color: '#FFFFFF',
  },
});

const SuccessModal = ({ onClose }) => {
  const styles = useStyles();
  return (
    <Modal key="success" onClose={() => onClose()}>
      <div className={styles.container}>
        <CheckOne className={styles.icon} onClick={() => onClose()}/>
        <div className={styles.text}>
          Done
        </div>
        <Button size="lg" type="primary" onClick={onClose}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
