import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5,
  },
  input: {
    fontSize: 16,
    background: 'transparent',
    border: '1px solid rgba(221, 214, 237, 0.5)',
    borderRadius: 12,
    outline: 'none',
    padding: 15,
    height: 24,
    fontFamily: 'Jost',
    color: ({ value }) => (value ? '#FFFFFF' : 'rgba(221, 214, 237, 0.5)'),
    paddingRight: ({ icon }) => (icon ? 40 : undefined),

    '&:hover': {
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
    },

    '&:focus': {
      border: '1px solid #7650DC',
      color: '#FFFFFF',
    },
  },
  error: {
    border: '1px solid #ff65657a',

    '&:hover': {
      border: '1px solid #ff65657a',
    },

    '&:focus': {
      border: '1px solid #ff65657a',
    },
  },
  labelContainer: {
    display: 'flex',
    columnGap: 3,
  },
  label: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    fontFamily: 'Jost',
    fontStyle: 'normal',
  },
  optionalLabel: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '22px',
    color: '#52CBE7',
  },
  disabled: {
    background: 'rgba(221, 214, 237, 0.1)',

    '&:hover': {
      border: '1px solid rgba(221, 214, 237, 0.5) !important',
    },
  },
  icon: {
    position: 'absolute',
    right: 10,
    top: 16,
  },
});


const Input = (
  {
    error,
    label,
    disabled,
    className,
    placeHolder,
    value,
    onChange,
    isRequired,
    icon,
    max,
    isNumeric,
  },
) => {
  const styles = useStyles({ icon, value });

  return (
    <div className={classNames(styles.container, className)}>
      {
        label ?
          (
            <div className={styles.labelContainer}>
              <div className={styles.label}>
                {label}
                {isRequired ? '' : ' (optional)'}
              </div>
            </div>
          ) :
          null
      }
      <input
        disabled={disabled}
        className={classNames(styles.input, disabled ? styles.disabled : null, error ? styles.error : null)}
        placeholder={placeHolder || ''}
        value={value || ''}
        onChange={(e) => {
          const val = (e.target.value || null);
          if (val && isNumeric) {
            if (val && /^[0-9.]+$/.test(val) && (val.split('.').length < 3)) {
              onChange(val);
            }
          } else {
            onChange(val);
          }
        }}
        maxLength={max}
      />
      {icon ? (<div className={styles.icon}>{icon}</div>) : null}
    </div>
  );
};

Input.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  error: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isNumeric: PropTypes.bool,
  max: PropTypes.number,
};

Input.defaultProps = {
  max: undefined,
  error: false,
  isNumeric: false,
  isRequired: false,
  icon: null,
  label: null,
  className: null,
  placeHolder: null,
  value: null,
  disabled: false,
};


export default Input;
