import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';


const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 100,
    height: 70,
    width: 70,
    '& $border': {
      padding: '0 44px',
    },
  },
  primary: {
    background: 'linear-gradient(113.26deg, #46FF7A 9.86%, #52CBE7 56.35%, #7650DC 105.85%)',
  },
  border: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
    border: 'none',
    background: 'none',
    padding: 1,
    borderRadius: 100,
  },
});


const GradientCircle = ({ children, type, className }) => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.container, styles[type], className)}>
      <div className={styles.border}>{children}</div>
    </div>
  );
};


GradientCircle.propTypes = {
  type: PropTypes.oneOf(['primary']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};


GradientCircle.defaultProps = {
  className: undefined,
  type: 'primary',
};


export default GradientCircle;
