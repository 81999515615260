import React, { useContext, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { Search } from '@icon-park/react';
import PageWrapper from '../PageWrapper';
import Table from '../Table';
import Button from '../Button';
import Delete from '../../icons/delete.svg';
import Checked from '../../icons/checked.svg';
import { deleteUpload, getUploads } from '../../api/file';
import { downloadFile } from '../../utils/file';
import DeleteUploadModal from './DeleteUploadModal';
import SuccessModal from '../SuccessModal';
import FullScreenSpinner from '../FullScreenSpinner';
import ForbiddenModal from '../ForbiddenModal';
import UserContext from '../../context/UserContext';
import Input from '../Input';
import PrivateFile from '../../icons/private_file.svg';
import PublicFile from '../../icons/public_file.svg';


const useStyles = createUseStyles({
  container: {
    position: 'relative',
    padding: '30px 40px',
    borderRadius: 30,
    background: '#020627',
  },
  hash: {
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: '#8B55FF',
    },
  },
  input: {
    position: 'absolute',
    width: 344,
    right: 0,
    top: -89,

    '& input': {
      background: 'rgba(2, 6, 39, 0.5)',
      border: '1px solid rgba(118, 80, 220, 0.2)',

      '&:hover': {
        border: '1px solid rgba(118, 80, 220, 0.6)',
        color: '#FFFFFF',
      },

      '&:focus': {
        border: '1px solid rgba(118, 80, 220, 1)',
        color: '#FFFFFF',
      },
    },

    '& svg': {
      opacity: 0.5,
    },
  },
  fileName: {
    maxWidth: 250,
    overflowWrap: 'anywhere',
  },
});


const getColumns = (
  navigate,
  styles,
  setDeleteCallback,
  setShowSuccessModal,
  setIsFileLoading,
  setShowForbiddenModal,
) => [
  {
    label: 'Chainback Hash',
    getValue: (item) => (
      <div className={styles.hash} onClick={() => navigate(`/uploads/${item.id}`)}>
        {(item.isPublic === false) ? <PrivateFile/> : <PublicFile/>}
        &nbsp;
        {`${item.id.substring(0, 15)}...`}
      </div>
    ),
  },
  {
    label: 'Age',
    getValue: (item) => (
      (new Date((new Date()).getTime() - (new Date(item.createdAt)).getTime())).formatRemainingTime()
    ),
  },
  { id: 'fileName', label: 'Filename', className: styles.fileName },
  {
    label: 'ChainbackSign',
    align: 'center',
    getValue: (item) => (
      (item.isSignEnabled && item.isTransactionCommitted) ?
        (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Checked/>
            &nbsp;
            <span style={{ fontWeight: 700, fontSize: 15 }}>ChainbackSign</span>
          </div>
        ) :
        null
    ),
    defaultValue: ' ',
  },
  {
    align: 'right',
    getValue: (item) => (
      <Button
        size="sm"
        type="primary"
        onClick={() => {
          setIsFileLoading(true);
          downloadFile(item.id, item.ipfsHash).then(
            () => setIsFileLoading(false),
          ).catch(
            () => {
              setIsFileLoading(false);
              setShowForbiddenModal(true);
            },
          );
        }}
      >
        Download
      </Button>
    ),
  },
  {
    align: 'right',
    getValue: (item, refresh) => (
      (item.isSignEnabled && item.isTransactionCommitted) ?
        null :
        (
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setDeleteCallback({
              delete: () => deleteUpload(item.id).then(refresh).then(() => {
                setDeleteCallback(null);
                setShowSuccessModal(true);
              }),
            })}
          >
            <Delete/>
            &nbsp;
            <span style={{ color: '#F30A80', fontWeight: 800, fontSize: 15 }}>Delete</span>
          </div>
        )
    ),
    defaultValue: ' ',
  },
];


const UploadsPage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [deleteCallback, setDeleteCallback] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [showForbiddenModal, setShowForbiddenModal] = useState(false);
  const [filter, setFilter] = useState('');
  const tableRef = useRef();

  if (!user) {
    navigate('/');
    return null;
  }

  return (
    <PageWrapper
      header="My Uploads"
      linksChain={[{ value: '/', label: 'Chainback' }, { value: '/uploads', label: 'My Uploads' }]}
    >
      <div className={styles.container}>
        <Input
          placeHolder="Search..."
          className={styles.input}
          value={filter}
          onChange={(value) => {
            setFilter(value);
            tableRef?.current?.refresh(value);
          }}
          icon={<Search size={24} fill="#DDD6ED"/>}
        />
        {showForbiddenModal ? <ForbiddenModal onClose={() => setShowForbiddenModal(false)}/> : null}
        {isFileLoading ? <FullScreenSpinner/> : null}
        {
          deleteCallback ?
            (
              <DeleteUploadModal
                onClose={() => setDeleteCallback(null)}
                onConfirm={() => deleteCallback.delete()}
              />
            ) :
            null
        }
        {showSuccessModal ? <SuccessModal onClose={() => setShowSuccessModal(false)}/> : null}
        <Table
          parentRef={tableRef}
          columns={
            getColumns(
              navigate,
              styles,
              setDeleteCallback,
              setShowSuccessModal,
              setIsFileLoading,
              setShowForbiddenModal,
            )
          }
          loadItems={(limit, offset, search) => getUploads(limit, offset, search)}
        />
      </div>
    </PageWrapper>
  );
};


export default UploadsPage;
