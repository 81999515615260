import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Copy from '../../icons/copy.svg';
import Button from '../Button';
import Logo from '../../icons/logo.svg';
import Login from '../Login/Login';
import UserContext from '../../context/UserContext';
import { logout } from '../../utils/auth';
import UploadFileOptions from '../UploadFileOptions';
import FullScreenSpinner from '../FullScreenSpinner';
import ErrorModal from '../ErrorModal';


const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    columnGap: 40,
    margin: ({ scrollTop }) => {
      if (scrollTop < 45) {
        return '51px 19px';
      }
      if (scrollTop < 55) {
        return `${91 - scrollTop}px 19px`;
      }
      return '36px 19px';
    },
    // animation: ({ isInitialized }) => (isInitialized ? 'margin .5s ease-in-out' : undefined),
    // '-webkit-animation': ({ isInitialized }) => (isInitialized ? 'margin .5s ease- in -out' : undefined),
    transition: ({ isInitialized }) => (isInitialized ? 'margin 500ms ease-in-out' : undefined),
    '-webkit-transition': ({ isInitialized }) => (isInitialized ? 'margin 500ms ease-in-out' : undefined),
  },
  explorer: {
    marginLeft: 'auto',
    fontSize: 20,
  },
  userMenu: {
    position: 'absolute',
    top: 60,
    right: 0,
    padding: 15,
    width: 347,
    filter: 'drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.8))',
    background: 'linear-gradient(125deg, #1C224E 0%, #03061B 100%)',
    borderRadius: 30,
    zIndex: 999,
  },
  row: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '22px',
    display: 'flex',
    rowGap: 8,
    flexDirection: 'column',
    color: '#DDD6ED',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '20px 0',
    alignItems: 'flex-start',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    width: '100%',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '22px',

    '&:hover': {
      color: '#8B55FF',
    },
  },
  copyRowLink: {
    cursor: 'pointer',

    '&:hover': {
      color: '#8B55FF',
    },
  },
  copyRow: {
    display: 'flex',
    columnGap: 10,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '22px',
    color: '#FFFFFF',
    fontFamily: 'Jost',
    fontStyle: 'normal',
  },
  copySign: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(221, 214, 237, 0.5)',
    cursor: 'pointer',

    '&:hover': {
      '& g': {
        opacity: 1,

        '& path': {
          stroke: '#8B55FF',
        },
      },
    },
  },
  signOut: {
    width: '100%',
    marginTop: 24,
  },
  userMenuButton: {
    fontSize: 20,
    fontWeight: 700,
  },
  fileInput: {
    display: 'none',
  },
  logoCircle: {
    cursor: 'pointer',
  },
  uploadFile: {
    fontSize: 20,
    fontWeight: 700,
  },
});

const renderCopyRow = (value, styles) => (
  value ?
    (
      <div className={styles.copyRow}>
        <div
          className={styles.copyRowLink}
          onClick={() => window.open(`${window.ETHERSCAN_URL}/address/${value}`, '_blank')}
        >
          {`${value.substring(0, 12)}...${value.substring(value.length - 12)}`}
        </div>
        <div>
          <Copy
            data-tooltip-id="copy-tooltip"
            className={styles.copySign}
            onClick={() => navigator.clipboard.writeText(value)}
          />
        </div>
      </div>
    ) :
    null
);

const Header = ({ scrollTop }) => {
  const [isInitialized, setIsInitialized] = useState();
  const styles = useStyles({ scrollTop, isInitialized });
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);

  useEffect(() => {
    const onClick = (e) => {
      if (
        (
          e.target.className &&
          ((typeof e.target.className) === 'string') &&
          !e.target.className.includes(styles.userMenu) &&
          !e.target.className.includes(styles.userMenuButton)
        ) &&
        !e.target.closest(`.${styles.userMenu}`) &&
        !e.target.closest(`.${styles.userMenuButton}`)
      ) {
        setShowUserMenu(false);
      }
    };
    document.addEventListener('click', onClick);
    setTimeout(() => setIsInitialized(true), 500);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <div className={styles.container}>
      {isUploadingInProgress ? <FullScreenSpinner text={`${uploadingPercentage}%`} size={160}/> : null}
      <div className={styles.logoCircle} onClick={() => navigate('/')}>
        <Logo/>
      </div>
      <Button className={styles.explorer} type="link" onClick={() => navigate('/explorer')}>
        Explorer
      </Button>
      <Button
        className={styles.uploadFile}
        onClick={() => {
          document.getElementsByClassName(styles.fileInput)[0].click();
        }}
      >
        Upload file
      </Button>
      {error ? <ErrorModal onClose={() => setError(null)} text={error}/> : null}
      {
        file ?
          (
            <UploadFileOptions
              onClose={(errorMessage) => {
                setFile(null);
                setUploadingPercentage(0);
                setIsUploadingInProgress(false);
                setError(errorMessage);
              }}
              file={file}
              progressCallback={setUploadingPercentage}
            />
          ) :
          null
      }
      <input
        type="file"
        className={styles.fileInput}
        onChange={(e) => {
          const attachedFile = e.target.files[0];
          if (attachedFile) {
            setFile(attachedFile);
            setIsUploadingInProgress(true);
          }
        }}
      />
      {
        user?.id ?
          (
            <>
              <Button
                type="reverseDarkOutline"
                className={styles.userMenuButton}
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {`${user.walletId.substring(0, 8)}...${user.walletId.substring(user.walletId.length - 3)}`}
              </Button>
              {
                showUserMenu ?
                  (
                    <div className={styles.userMenu}>
                      <div className={styles.row}>
                        <div>Wallet</div>
                        {/* eslint-disable-next-line react/destructuring-assignment */}
                        {renderCopyRow(user.walletId, styles)}
                      </div>
                      <div
                        className={styles.row}
                        onClick={() => {
                          navigate('/uploads');
                          setShowUserMenu(false);
                        }}
                      >
                        <div className={styles.link}>
                          My Uploads
                        </div>
                      </div>
                      <Button
                        className={styles.signOut}
                        type="reversePrimary"
                        size="sm"
                        onClick={() => {
                          logout();
                          setShowUserMenu(false);
                        }}
                      >
                        Sign out
                      </Button>
                    </div>
                  ) :
                  null
              }
            </>
          ) :
          (<Login/>)
      }
    </div>
  );
};

Header.propTypes = {
  scrollTop: PropTypes.number,
};

Header.defaultProps = {
  scrollTop: 0,
};


export default Header;
