import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import Error from '../../icons/error.svg';
import Modal from '../Modal';
import Button from '../Button';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 24,
  },
  icon: {
    width: 94,
    height: 94,
    color: '#F5576E',
  },
  text: {
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '46px',
    color: '#FFFFFF',
  },
});

const ForbiddenModal = ({ onClose }) => {
  const styles = useStyles();
  return (
    <Modal key="forbidden" onClose={() => onClose()}>
      <div className={styles.container}>
        <Error className={styles.icon} size={94}/>
        <div className={styles.text}>
          Access to this file is forbidden
        </div>
        <Button size="lg" type="reversePrimaryLight" onClick={onClose}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};

ForbiddenModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ForbiddenModal;
