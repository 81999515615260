import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Copy from '../../icons/copy.svg';
import Ethereum from '../../icons/ethereum.svg';
import ERC20 from '../../icons/erc20.svg';
import NFT from '../../icons/nft.svg';
import Earth from '../../icons/earth.svg';
import Button from '../Button';
import GradientText from '../GradientText';
import Table from '../Table';
import { downloadFile } from '../../utils/file';
import SignFileModal from '../SignFileModal';
import { getSignatures } from '../../api/file';
import ForbiddenModal from '../ForbiddenModal';
import CheckTransactionStatusModal from '../CheckTransactionStatusModal';
import { handleLogin } from '../../utils/web3-connector';
import UserContext from '../../context/UserContext';
import PrivateFile from '../../icons/private_file.svg';
import PublicFile from '../../icons/public_file.svg';
import File from '../../icons/file.svg';


const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 50,
  },
  overview: {
    display: 'flex',
    flexDirection: 'column',
    background: '#020627',
    borderRadius: 30,
    padding: 40,
  },
  blockHeader: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    padding: '19px 0',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  },
  blockRow: {
    columnGap: 40,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '19px 0',
  },
  blockLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 1,
  },
  blockValue: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
    flex: 4,
  },
  copyRow: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    fontSize: 16,
    lineHeight: '23px',
    color: '#DDD6ED',
  },
  linkRow: {
    cursor: 'pointer',
    fontWeight: 700,

    '&:hover': {
      color: '#8B55FF',

      '& $copySign': {
        color: '#8B55FF',
      },
    },
  },
  fileLinkRow: {
    padding: 16,
    border: '1px solid rgba(221, 214, 237, 0.5)',
    borderRadius: 12,
    columnGap: 15,
    background: 'rgba(2, 6, 39, 0.5)',

    '& div': {
      '& span': {
        fontWeight: 700,
        fontSize: 22,
        lineHeight: '24px',
        letterSpacing: '0.005em',
      },
    },
  },
  copySign: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(221, 214, 237, 0.5)',

    '&:hover': {
      '& g': {
        opacity: 1,

        '& path': {
          stroke: '#8B55FF',
        },
      },
    },
  },
  buttons: {
    display: 'flex',
    columnGap: 20,
  },
  signatures: {
    background: '#020627',
    borderRadius: 30,
    padding: 40,
  },
  hash: {
    cursor: 'pointer',
    fontWeight: 600,

    '&:hover': {
      color: '#8B55FF',
    },
  },
  fromBlock: {
    '& $copyRow': {
      '& $linkRow': {
        fontWeight: 600,
        fontSize: 16,
      },
    },
  },
  comment: {
    minWidth: 400,
    maxWidth: 650,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
});


const renderCopyRow = (label, value, styles, isLink, short, isFileLink, hideCopy) => {
  if (!value) {
    return null;
  }
  let displayLabel = (label || value);
  if (short) {
    displayLabel = `${displayLabel.substring(0, 4)}...${displayLabel.substring(displayLabel.length - 4)}`;
  }
  return (
    <div className={classNames(styles.copyRow, isFileLink ? styles.fileLinkRow : null)}>
      <div
        className={isLink ? styles.linkRow : null}
        onClick={() => (isLink ? window.open(value, '_blank') : null)}
      >
        {
          isFileLink ?
            <GradientText colorFrom="#FA26CA" colorTo="#8B55FF">{displayLabel}</GradientText> :
            displayLabel
        }
      </div>
      {
        hideCopy ?
          null :
          (
            <div>
              <Copy
                data-tooltip-id="copy-tooltip"
                className={styles.copySign}
                onClick={() => navigator.clipboard.writeText(label || value)}
              />
            </div>
          )
      }
    </div>
  );
};


const getColumns = (id, navigate, styles, isFromExplorer, isFromMain) => [
  {
    label: 'tx ID',
    getValue: (item) => (
      <div
        className={(item.transactionId && item.isTransactionFinished) ? styles.hash : undefined}
        onClick={
          () => (
            (item.id && item.isTransactionFinished) ?
              navigate(
                // eslint-disable-next-line max-len
                `/uploads/${id}/signatures/${item.transactionId}${isFromExplorer ? '?isFromExplorer=true' : ''}${isFromMain ? '?isFromMain=true' : ''}`,
              ) :
              null
          )
        }
      >
        {`${item.id.substring(0, 15)}...`}
      </div>
    ),
  },
  {
    label: 'Age',
    getValue: (item) => (
      new Date((new Date()).getTime() - (new Date(item.createdAt)).getTime())
    ).formatRemainingTime(),
  },
  {
    id: 'from',
    label: 'From',
    getValue: (item) => (
      <div className={styles.fromBlock}>
        {
          renderCopyRow(
            item.from,
            `${window.ETHERSCAN_URL}/address/${item.from}`,
            styles,
            true,
            true,
            false,
            true,
          )
        }
      </div>
    ),
  },
  { id: 'name', label: 'Name' },
  { id: 'comment', label: 'Comment', className: styles.comment, minWidth: 400, maxWidth: 650, width: 650 },
];


const SignEnabledFileDetailsPage = ({ upload }) => {
  const styles = useStyles();
  const tableRef = useRef();
  const navigate = useNavigate();
  const [showSignFileModal, setShowSignFileModal] = useState(false);
  const [showForbiddenModal, setShowForbiddenModal] = useState(false);
  const [waitTransactionModal, setWaitTransactionModal] = useState();
  const user = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const isFromExplorer = searchParams.get('isFromExplorer');
  const isFromMain = searchParams.get('isFromMain');

  return (
    <div className={styles.container}>
      {showForbiddenModal ? <ForbiddenModal onClose={() => setShowForbiddenModal(false)}/> : null}
      {
        showSignFileModal ?
          (
            <SignFileModal
              onConfirm={(transactionHash) => {
                if (transactionHash) {
                  setShowSignFileModal(false);
                  setWaitTransactionModal(transactionHash);
                } else {
                  setShowSignFileModal(false);
                  setShowForbiddenModal(true);
                }
              }}
              onClose={() => setShowSignFileModal(false)}
              upload={upload}
            />
          ) :
          null
      }
      {
        waitTransactionModal ?
          (
            <CheckTransactionStatusModal
              transactionHash={waitTransactionModal}
              onClose={() => {
                setWaitTransactionModal(null);
                tableRef?.current?.refresh();
              }}
            />
          ) :
          null
      }
      <div className={styles.overview}>
        <div className={styles.blockHeader}>Overview</div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Ethereum Hash
          </div>
          <div className={styles.blockValue}>
            {
              upload.transactionHash ?
                renderCopyRow(
                  upload.transactionHash,
                  `${window.ETHERSCAN_URL}/tx/${upload.transactionHash}`,
                  styles,
                  true,
                ) :
                '-'
            }
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            IPFS Hash
          </div>
          <div className={styles.blockValue}>
            {renderCopyRow(null, upload.ipfsHash, styles)}
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Chainback Hash
          </div>
          <div className={styles.blockValue}>
            {(upload.isPublic === false) ? <PrivateFile/> : <PublicFile/>}
            {renderCopyRow(null, upload.id, styles)}
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Block
          </div>
          <div className={styles.blockValue}>
            {upload.transaction?.block}
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Timestamp
          </div>
          <div className={styles.blockValue}>
            {(new Date((new Date()).getTime() - upload.createdAt.getTime())).formatRemainingTime()}
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            From
          </div>
          <div className={styles.blockValue}>
            {
              upload.transaction?.from ?
                renderCopyRow(
                  upload.transaction.from,
                  `${window.ETHERSCAN_URL}/address/${upload.transaction.from}`,
                  styles,
                  true,
                ) :
                '-'
            }
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            ARCHIVE fee
          </div>
          <div className={styles.blockValue}>
            {upload.feeArchive || '-'}
            &nbsp;
            ARCHIVE
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            ETH fee
          </div>
          <div className={styles.blockValue}>
            {upload?.transaction?.fee || '-'}
            &nbsp;
            ETH
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            File Name
          </div>
          <div className={styles.blockValue}>
            <File/>
            &nbsp;
            {upload.fileName}
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Description
          </div>
          <div className={styles.blockValue}>
            {upload.description}
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            File Download and Signature
          </div>
          <div className={styles.blockValue}>
            <div className={styles.buttons}>
              <Button
                size="sm"
                type="primary"
                onClick={() => downloadFile(upload.id, upload.ipfsHash).catch(() => setShowForbiddenModal(true))}
              >
                Download
              </Button>
              {
                (
                  upload.signSpecificAddressesVisibility ||
                  upload.signErc20TokensAddressesVisibility ||
                  upload.signNftTokensVisibility
                ) ?
                  (
                    <Button
                      size="sm"
                      backgroundColor="#020627"
                      onClick={() => {
                        if (user) {
                          setShowSignFileModal(true);
                        } else {
                          handleLogin().then(() => setShowSignFileModal(true)).catch(() => {
                          });
                        }
                      }}
                      disabled={!upload.transaction?.ethereumHash}
                    >
                      Sign Document
                    </Button>
                  ) :
                  null
              }
            </div>
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            File link
          </div>
          <div className={styles.blockValue}>
            <div className={styles.blockValue}>
              {renderCopyRow(null, `${window.location.origin}/uploads/${upload.id}`, styles, false, false, true)}
            </div>
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Download
          </div>
          <div className={styles.blockValue}>
            {
              upload.erc20TokensAddressesVisibility ?
                // eslint-disable-next-line max-len
                <ERC20 data-tooltip-id="restriction-tooltip" data-tooltip-content={`Download only for owners of ERC20 tokens:\n${upload.erc20TokenAddresses}${upload.erc20TokenAddressMinAmount ? ` (${upload.erc20TokenAddressMinAmount})` : ''}`}/> :
                null
            }
            {
              upload.nftTokensVisibility ?
                // eslint-disable-next-line max-len
                <NFT data-tooltip-id="restriction-tooltip" data-tooltip-content={`Download only for owners of NFT:\n${upload.nftTokenAddresses}`}/> :
                null
            }
            {
              upload.specificAddressesVisibility ?
                // eslint-disable-next-line max-len
                <Ethereum data-tooltip-id="restriction-tooltip" data-tooltip-content="Download only for specific addresses"/> :
                null
            }
            {
              (
                !upload.specificAddressesVisibility &&
                !upload.erc20TokensAddressesVisibility &&
                !upload.nftTokensVisibility
              ) ?
                <Earth data-tooltip-id="restriction-tooltip" data-tooltip-content="Public"/> :
                null
            }
          </div>
        </div>
        <div className={styles.blockRow}>
          <div className={styles.blockLabel}>
            Signature
          </div>
          <div className={styles.blockValue}>
            {
              upload.signErc20TokensAddressesVisibility ?
                // eslint-disable-next-line max-len
                <ERC20 data-tooltip-id="restriction-tooltip" data-tooltip-content={`Sign only for owners of ERC20 tokens:\n${upload.signErc20TokenAddresses}${upload.signErc20TokenAddressMinAmount ? ` (${upload.signErc20TokenAddressMinAmount})` : ''}`}/> :
                null
            }
            {
              upload.signNftTokensVisibility ?
                // eslint-disable-next-line max-len
                <NFT data-tooltip-id="restriction-tooltip" data-tooltip-content={`Sign only for owners of NFT:\n${upload.signNftTokenAddresses}`}/> :
                null
            }
            {
              upload.signSpecificAddressesVisibility ?
                // eslint-disable-next-line max-len
                <Ethereum data-tooltip-id="restriction-tooltip" data-tooltip-content="Sign only from specific addresses"/> :
                null
            }
            {
              (
                !upload.signSpecificAddressesVisibility &&
                !upload.signErc20TokensAddressesVisibility &&
                !upload.signNftTokensVisibility
              ) ?
                <Earth data-tooltip-id="restriction-tooltip" data-tooltip-content="Public"/> :
                null
            }
          </div>
        </div>
      </div>
      <div className={styles.signatures}>
        <div className={styles.blockHeader}>
          ChainbackSign File Signatures
        </div>
        <Table
          parentRef={tableRef}
          columns={getColumns(upload.id, navigate, styles, isFromExplorer, isFromMain)}
          loadItems={(limit, offset) => getSignatures(upload.id, limit, offset)}
        />
      </div>
    </div>
  );
};

SignEnabledFileDetailsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  upload: PropTypes.object.isRequired,
};


export default SignEnabledFileDetailsPage;
